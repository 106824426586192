"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentType = exports.MortgageLender = void 0;
/** @TranslateToPython @TranslateToEnum */
var MortgageLender;
(function (MortgageLender) {
    MortgageLender["rocketMoney"] = "rocketMoney";
    MortgageLender["carrington"] = "carrington";
})(MortgageLender = exports.MortgageLender || (exports.MortgageLender = {}));
var EmploymentType;
(function (EmploymentType) {
    EmploymentType["employed"] = "employed";
    EmploymentType["selfEmployed"] = "selfEmployed";
    EmploymentType["retired"] = "retired";
    EmploymentType["unemployed"] = "unemployed";
    EmploymentType["other"] = "other";
})(EmploymentType = exports.EmploymentType || (exports.EmploymentType = {}));
