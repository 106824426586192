"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllinoisCounty = void 0;
/**  @TranslateToPython @TranslateToEnum  */
var IllinoisCounty;
(function (IllinoisCounty) {
    IllinoisCounty["adams"] = "adams";
    IllinoisCounty["alexander"] = "alexander";
    IllinoisCounty["bond"] = "bond";
    IllinoisCounty["boone"] = "boone";
    IllinoisCounty["brown"] = "brown";
    IllinoisCounty["bureau"] = "bureau";
    IllinoisCounty["calhoun"] = "calhoun";
    IllinoisCounty["carroll"] = "carroll";
    IllinoisCounty["cass"] = "cass";
    IllinoisCounty["champaign"] = "champaign";
    IllinoisCounty["christian"] = "christian";
    IllinoisCounty["clark"] = "clark";
    IllinoisCounty["clay"] = "clay";
    IllinoisCounty["clinton"] = "clinton";
    IllinoisCounty["coles"] = "coles";
    IllinoisCounty["cook"] = "cook";
    IllinoisCounty["crawford"] = "crawford";
    IllinoisCounty["cumberland"] = "cumberland";
    IllinoisCounty["deKalb"] = "dekalb";
    IllinoisCounty["deWitt"] = "dewitt";
    IllinoisCounty["douglas"] = "douglas";
    IllinoisCounty["duPage"] = "dupage";
    IllinoisCounty["edgar"] = "edgar";
    IllinoisCounty["edwards"] = "edwards";
    IllinoisCounty["effingham"] = "effingham";
    IllinoisCounty["fayette"] = "fayette";
    IllinoisCounty["ford"] = "ford";
    IllinoisCounty["franklin"] = "franklin";
    IllinoisCounty["fulton"] = "fulton";
    IllinoisCounty["gallatin"] = "gallatin";
    IllinoisCounty["greene"] = "greene";
    IllinoisCounty["grundy"] = "grundy";
    IllinoisCounty["hamilton"] = "hamilton";
    IllinoisCounty["hancock"] = "hancock";
    IllinoisCounty["hardin"] = "hardin";
    IllinoisCounty["henderson"] = "henderson";
    IllinoisCounty["henry"] = "henry";
    IllinoisCounty["iroquois"] = "iroquois";
    IllinoisCounty["jackson"] = "jackson";
    IllinoisCounty["jasper"] = "jasper";
    IllinoisCounty["jefferson"] = "jefferson";
    IllinoisCounty["jersey"] = "jersey";
    IllinoisCounty["joDaviess"] = "jo daviess";
    IllinoisCounty["johnson"] = "johnson";
    IllinoisCounty["kane"] = "kane";
    IllinoisCounty["kankakee"] = "kankakee";
    IllinoisCounty["kendall"] = "kendall";
    IllinoisCounty["knox"] = "knox";
    IllinoisCounty["lake"] = "lake";
    IllinoisCounty["laSalle"] = "lasalle";
    IllinoisCounty["lawrence"] = "lawrence";
    IllinoisCounty["lee"] = "lee";
    IllinoisCounty["livingston"] = "livingston";
    IllinoisCounty["logan"] = "logan";
    IllinoisCounty["mcDonough"] = "mcdonough";
    IllinoisCounty["mcHenry"] = "mchenry";
    IllinoisCounty["mcLean"] = "mclean";
    IllinoisCounty["macon"] = "macon";
    IllinoisCounty["macoupin"] = "macoupin";
    IllinoisCounty["madison"] = "madison";
    IllinoisCounty["marion"] = "marion";
    IllinoisCounty["marshall"] = "marshall";
    IllinoisCounty["mason"] = "mason";
    IllinoisCounty["massac"] = "massac";
    IllinoisCounty["menard"] = "menard";
    IllinoisCounty["mercer"] = "mercer";
    IllinoisCounty["monroe"] = "monroe";
    IllinoisCounty["montgomery"] = "montgomery";
    IllinoisCounty["morgan"] = "morgan";
    IllinoisCounty["moultrie"] = "moultrie";
    IllinoisCounty["ogle"] = "ogle";
    IllinoisCounty["peoria"] = "peoria";
    IllinoisCounty["perry"] = "perry";
    IllinoisCounty["piatt"] = "piatt";
    IllinoisCounty["pike"] = "pike";
    IllinoisCounty["pope"] = "pope";
    IllinoisCounty["pulaski"] = "pulaski";
    IllinoisCounty["putnam"] = "putnam";
    IllinoisCounty["randolph"] = "randolph";
    IllinoisCounty["richland"] = "richland";
    IllinoisCounty["rockIsland"] = "rock island";
    IllinoisCounty["stClair"] = "st. clair";
    IllinoisCounty["saline"] = "saline";
    IllinoisCounty["sangamon"] = "sangamon";
    IllinoisCounty["schuyler"] = "schuyler";
    IllinoisCounty["scott"] = "scott";
    IllinoisCounty["shelby"] = "shelby";
    IllinoisCounty["stark"] = "stark";
    IllinoisCounty["stephenson"] = "stephenson";
    IllinoisCounty["tazewell"] = "tazewell";
    IllinoisCounty["union"] = "union";
    IllinoisCounty["vermilion"] = "vermilion";
    IllinoisCounty["wabash"] = "wabash";
    IllinoisCounty["warren"] = "warren";
    IllinoisCounty["washington"] = "washington";
    IllinoisCounty["wayne"] = "wayne";
    IllinoisCounty["white"] = "white";
    IllinoisCounty["whiteside"] = "whiteside";
    IllinoisCounty["will"] = "will";
    IllinoisCounty["williamson"] = "williamson";
    IllinoisCounty["winnebago"] = "winnebago";
    IllinoisCounty["woodford"] = "woodford";
})(IllinoisCounty = exports.IllinoisCounty || (exports.IllinoisCounty = {}));
