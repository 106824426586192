"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataReportType = void 0;
/** @TranslateToPython @TranslateToEnum */
var DataReportType;
(function (DataReportType) {
    DataReportType["none"] = "none";
    // Application related reports
    DataReportType["loanApplicationReport"] = "loanApplicationReport";
    DataReportType["applicantReport"] = "applicantReport";
    DataReportType["homeReport"] = "homeReport";
    DataReportType["mailReport"] = "mailReport";
    DataReportType["manualOfferReport"] = "manualOfferReport";
    // Aven account data
    DataReportType["avenAccountDataReport"] = "avenAccountDataReport";
    // Credit reports
    DataReportType["experianSoftPullReport"] = "experianSoftPullReport";
    DataReportType["transUnionSoftPullReport"] = "transUnionSoftPullReport";
    DataReportType["equifaxSoftPullReport"] = "equifaxSoftPullReport";
    // experianHardPullReport = 'experianHardPullReport',   // Not used in UW.
    // Property reports
    DataReportType["houseCanaryValueAnalysisReport"] = "houseCanaryValueAnalysisReport";
    DataReportType["coreLogicSubjectPropertyDetailReport"] = "coreLogicSubjectPropertyDetailReport";
    DataReportType["coreLogicTransactionHistoryReport"] = "coreLogicTransactionHistoryReport";
    DataReportType["coreLogicInvoluntaryLiensReport"] = "coreLogicInvoluntaryLiensReport";
    DataReportType["coreLogicTHVRiskManagementReport"] = "coreLogicTHVRiskManagementReport";
    DataReportType["dataTreeProcisionPremierReport"] = "dataTreeProcisionPremierReport";
    DataReportType["dataTreeTotalViewReport"] = "dataTreeTotalViewReport";
    // Income reports
    DataReportType["workNumberDataReport"] = "workNumberDataReport";
    DataReportType["plaidIncomeReport"] = "plaidIncomeReport";
    DataReportType["manualIncomeVerification"] = "manualIncomeVerification";
    DataReportType["ocrolusReport"] = "ocrolusReport";
    DataReportType["informedIQReport"] = "informedIQReport";
    DataReportType["irsReport"] = "irsReport";
    DataReportType["experianVerifyReport"] = "experianVerifyReport";
    // trueworkReport = 'trueworkReport',
    // not used
    // houseCanaryAgileValueReport = 'houseCanaryAgileValueReport',
    // houseCanaryLtvReport = 'houseCanaryLtvReport',
})(DataReportType = exports.DataReportType || (exports.DataReportType = {}));
