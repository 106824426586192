"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnderwritingResponseType = exports.RateIndexType = exports.PythonBucketProductType = exports.UnderwritingReportName = exports.LoanApplicationOverrideType = exports.UnderwritingRuleCategory = exports.HomeUnderwritingMode = exports.UCC_MAX_APR = exports.AUTO_CREDIT_CARD_ACCOUNT_MAX_APR = exports.HOME_CREDIT_CARD_ACCOUNT_MAX_APR = exports.OfferFailureCode = void 0;
/**
 * Reasons why Aven card offer generation may have failed. Notice this is a superset of
 * UnderwritingFailureCode, meant to account for both underwriting failures and non-underwriting
 * failures. This is our external error interface communicated to our client code.
 */
var OfferFailureCode;
(function (OfferFailureCode) {
    OfferFailureCode["homeOwnership"] = "homeOwnership";
    OfferFailureCode["homeOwnershipRemedy"] = "homeOwnershipRemedy";
    OfferFailureCode["homeOwnershipRemedyTokenMatch"] = "homeOwnershipRemedyTokenMatch";
    OfferFailureCode["homeIsLLC"] = "homeIsLLC";
    OfferFailureCode["homeIsTrustAndRin"] = "homeIsTrustAndRin";
    OfferFailureCode["homeIsTrustAndNOO"] = "homeIsTrustAndNOO";
    OfferFailureCode["homeIsTrustInNotSupportedState"] = "homeIsTrustInNotSupportedState";
    OfferFailureCode["humanInvestigate"] = "humanInvestigate";
    OfferFailureCode["involuntaryLienFound"] = "involuntaryLienFound";
    OfferFailureCode["activeForeclosureFound"] = "activeForeclosureFound";
    OfferFailureCode["ofacVerification"] = "ofacVerification";
    OfferFailureCode["denied"] = "denied";
    OfferFailureCode["expired"] = "expired";
    OfferFailureCode["priorApplicationFound"] = "priorApplicationFound";
    OfferFailureCode["offerAddCoApplicant"] = "offerAddCoApplicant";
    OfferFailureCode["blockedApplication"] = "blockedApplication";
    OfferFailureCode["underwritingFail"] = "underwritingFail";
    OfferFailureCode["disputableProviderData"] = "disputableProviderData";
    OfferFailureCode["lienInformationVerification"] = "lienInformationVerification";
    OfferFailureCode["missingLienAttestation"] = "lienAttestationMissing";
    OfferFailureCode["withdrawn"] = "withdrawn";
    OfferFailureCode["uccInsufficientIncome"] = "uccInsufficientIncome";
    OfferFailureCode["uccAutoAccept"] = "uccAutoAccept";
})(OfferFailureCode = exports.OfferFailureCode || (exports.OfferFailureCode = {}));
/** @TranslateToPython @TranslateToEnum */
exports.HOME_CREDIT_CARD_ACCOUNT_MAX_APR = 0.18;
/**
 * @TranslateToPython @TranslateToEnum
 * not currently used
 */
exports.AUTO_CREDIT_CARD_ACCOUNT_MAX_APR = 0.2;
/** @TranslateToPython @TranslateToEnum */
exports.UCC_MAX_APR = 0.36;
/**  @TranslateToPython @TranslateToEnum  */
var HomeUnderwritingMode;
(function (HomeUnderwritingMode) {
    // TODO: split out origination for preQualificatoin and qualification UW
    HomeUnderwritingMode["origination"] = "origination";
    HomeUnderwritingMode["experianOnly"] = "experianOnly";
    HomeUnderwritingMode["annualRenewal"] = "annualRenewal";
    HomeUnderwritingMode["preAccountApprovalChecks"] = "preAccountApprovalChecks";
    HomeUnderwritingMode["postAccountOpeningLienCheck"] = "postAccountOpeningLienCheck";
    HomeUnderwritingMode["lineSizeIncrease"] = "lineSizeIncrease";
    HomeUnderwritingMode["lineSizeIncreaseExperianOnly"] = "lineSizeIncreaseExperianOnly";
    HomeUnderwritingMode["aprReduction"] = "aprReduction";
    HomeUnderwritingMode["mortgageBrokering"] = "mortgageBrokering";
    HomeUnderwritingMode["lienInfoCalculation"] = "lienInfoCalculation";
    HomeUnderwritingMode["fullReUnderwriting"] = "fullReUnderwriting";
    HomeUnderwritingMode["getLienInfo"] = "getLienInfo";
    HomeUnderwritingMode["parsePlaidReport"] = "parsePlaidReport";
    HomeUnderwritingMode["ucc"] = "ucc";
})(HomeUnderwritingMode = exports.HomeUnderwritingMode || (exports.HomeUnderwritingMode = {}));
/**  @TranslateToPython @TranslateToEnum  */
var UnderwritingRuleCategory;
(function (UnderwritingRuleCategory) {
    UnderwritingRuleCategory["timeSinceLastMortgage"] = "timeSinceLastMortgage";
    UnderwritingRuleCategory["realEstateInquiries"] = "realEstateInquiries";
    UnderwritingRuleCategory["rateOnExistingMortgage"] = "rateOnExistingMortgage";
    UnderwritingRuleCategory["propertyTypes"] = "propertyTypes";
    UnderwritingRuleCategory["ficoAndDtiRisk"] = "ficoAndDtiRisk";
    UnderwritingRuleCategory["ignoreStatedUsage"] = "ignoreStatedUsage";
    UnderwritingRuleCategory["ignoreStatedUsage400K"] = "ignoreStatedUsage400K";
    UnderwritingRuleCategory["add25Bps"] = "add25Bps";
    UnderwritingRuleCategory["add50Bps"] = "add50Bps";
    UnderwritingRuleCategory["add75Bps"] = "add75Bps";
    UnderwritingRuleCategory["add100Bps"] = "add100Bps";
    UnderwritingRuleCategory["subtract25Bps"] = "subtract25Bps";
    UnderwritingRuleCategory["subtract50Bps"] = "subtract50Bps";
    UnderwritingRuleCategory["subtract75Bps"] = "subtract75Bps";
    UnderwritingRuleCategory["lowFicoAdd100Bps"] = "lowFicoAdd100Bps";
    UnderwritingRuleCategory["subtract20PercentLineSize"] = "subtract20PercentLineSize";
})(UnderwritingRuleCategory = exports.UnderwritingRuleCategory || (exports.UnderwritingRuleCategory = {}));
/**  @TranslateToPython @TranslateToEnum  */
var LoanApplicationOverrideType;
(function (LoanApplicationOverrideType) {
    LoanApplicationOverrideType["thirdLien"] = "thirdLien";
    LoanApplicationOverrideType["lienPosition"] = "lienPosition";
    LoanApplicationOverrideType["involuntaryLien"] = "involuntaryLien";
    LoanApplicationOverrideType["activeForeclosure"] = "activeForeclosure";
    LoanApplicationOverrideType["avmValue"] = "avmValue";
    LoanApplicationOverrideType["homeBalance"] = "homeBalance";
    LoanApplicationOverrideType["firstLienBalance"] = "firstLienBalance";
    LoanApplicationOverrideType["secondLienBalance"] = "secondLienBalance";
    LoanApplicationOverrideType["postLineMonthlyDebtPayments"] = "postLineMonthlyDebtPayments";
    LoanApplicationOverrideType["minimumGrossAnnualIncomeRequiredForOffer"] = "minimumGrossAnnualIncomeRequiredForOffer";
    LoanApplicationOverrideType["minimumGrossAnnualIncomeRequiredForMinOffer"] = "minimumGrossAnnualIncomeRequiredForMinOffer";
    LoanApplicationOverrideType["minimumGrossAnnualIncomeRequiredForPQOffer"] = "minimumGrossAnnualIncomeRequiredForPQOffer";
    LoanApplicationOverrideType["underwritingRuleCategories"] = "underwritingRuleCategories";
    LoanApplicationOverrideType["offerTermAprReduction"] = "offerTermAprReduction";
})(LoanApplicationOverrideType = exports.LoanApplicationOverrideType || (exports.LoanApplicationOverrideType = {}));
/**  @TranslateToPython @TranslateToEnum  */
var UnderwritingReportName;
(function (UnderwritingReportName) {
    UnderwritingReportName["AporValueReport"] = "AporValueReport";
    UnderwritingReportName["ApplicantReport"] = "ApplicantReport";
    UnderwritingReportName["AutoApplicantReport"] = "AutoApplicantReport";
    UnderwritingReportName["AutoApplicationReport"] = "AutoApplicationReport";
    UnderwritingReportName["AvenAccountData"] = "AvenAccountData";
    UnderwritingReportName["CoreLogicInvoluntaryLiensReport"] = "CoreLogicInvoluntaryLiensReport";
    UnderwritingReportName["CoreLogicSubjectPropertyDetailReport"] = "CoreLogicSubjectPropertyDetailReport";
    UnderwritingReportName["CoreLogicTHVRiskManagementReport"] = "CoreLogicTHVRiskManagementReport";
    UnderwritingReportName["CoreLogicTransactionHistoryReport"] = "CoreLogicTransactionHistoryReport";
    UnderwritingReportName["CryptoCardBalancesReport"] = "CryptoCardBalancesReport";
    UnderwritingReportName["CryptoCardLineSizesReport"] = "CryptoCardLineSizesReport";
    UnderwritingReportName["CryptoMaintenanceStatesReport"] = "CryptoMaintenanceStatesReport";
    UnderwritingReportName["CryptoPricesReport"] = "CryptoPricesReport";
    UnderwritingReportName["CryptoWalletBalancesReport"] = "CryptoWalletBalancesReport";
    UnderwritingReportName["DataTreeProcisionPremierReport"] = "DataTreeProcisionPremierReport";
    UnderwritingReportName["DataTreePropertyReport"] = "DataTreePropertyReport";
    UnderwritingReportName["DocAITaxFormReport"] = "DocAITaxFormReport";
    UnderwritingReportName["EquifaxReport"] = "EquifaxReport";
    UnderwritingReportName["ExperianReport"] = "ExperianReport";
    UnderwritingReportName["ExperianVerifyReport"] = "ExperianVerifyReport";
    UnderwritingReportName["GoogleDocAIReport"] = "GoogleDocAIReport";
    UnderwritingReportName["HomeReport"] = "HomeReport";
    UnderwritingReportName["HouseCanaryAgileValueReport"] = "HouseCanaryAgileValueReport";
    UnderwritingReportName["HouseCanaryLtvReport"] = "HouseCanaryLtvReport";
    UnderwritingReportName["HouseCanaryValueAnalysisReport"] = "HouseCanaryValueAnalysisReport";
    UnderwritingReportName["IncomeEntriesReport"] = "IncomeEntriesReport";
    UnderwritingReportName["IrsReport"] = "IrsReport";
    UnderwritingReportName["LienAttestationReport"] = "LienAttestationReport";
    UnderwritingReportName["LoanApplicationReport"] = "LoanApplicationReport";
    UnderwritingReportName["MailReport"] = "MailReport";
    UnderwritingReportName["ManualIncomeVerificationReport"] = "ManualIncomeVerificationReport";
    UnderwritingReportName["ManualLienInfoReport"] = "ManualLienInfoReport";
    UnderwritingReportName["ManualOfferReport"] = "ManualOfferReport";
    UnderwritingReportName["OcrolusReport"] = "OcrolusReport";
    UnderwritingReportName["PersonalPropertyAttestationInfoReport"] = "PersonalPropertyAttestationInfoReport";
    UnderwritingReportName["PlaidBalanceReport"] = "PlaidBalanceReport";
    UnderwritingReportName["PlaidBaseReport"] = "PlaidBaseReport";
    UnderwritingReportName["PlaidRecurringTransactionsReport"] = "PlaidRecurringTransactionsReport";
    UnderwritingReportName["PlaidReport"] = "PlaidReport";
    UnderwritingReportName["PlaidTransactionsReport"] = "PlaidTransactionsReport";
    UnderwritingReportName["TransUnionReport"] = "TransUnionReport";
    UnderwritingReportName["TrueworkReport"] = "TrueworkReport";
    UnderwritingReportName["UccAssetVerificationReport"] = "UccAssetVerificationReport";
    UnderwritingReportName["VehicleReport"] = "VehicleReport";
    UnderwritingReportName["WorkNumberReport"] = "WorkNumberReport";
})(UnderwritingReportName = exports.UnderwritingReportName || (exports.UnderwritingReportName = {}));
/**  @TranslateToPython @TranslateToEnum  */
var PythonBucketProductType;
(function (PythonBucketProductType) {
    PythonBucketProductType["ZERO_REVOLVING_APR"] = "ZERO_REVOLVING_APR";
    PythonBucketProductType["DEFAULT"] = "DEFAULT";
})(PythonBucketProductType = exports.PythonBucketProductType || (exports.PythonBucketProductType = {}));
// TODO: Need to have one record for each type in rate_index table
var RateIndexType;
(function (RateIndexType) {
    RateIndexType["WSJ_PRIME"] = "WSJ_PRIME";
    RateIndexType["WSJ_PRIME_TRACKER"] = "WSJ_PRIME_TRACKER";
    RateIndexType["FFTRUL"] = "FFTRUL";
})(RateIndexType = exports.RateIndexType || (exports.RateIndexType = {}));
var UnderwritingResponseType;
(function (UnderwritingResponseType) {
    UnderwritingResponseType["DENIED"] = "DENIED";
    UnderwritingResponseType["INELIGIBLE"] = "INELIGIBLE";
})(UnderwritingResponseType = exports.UnderwritingResponseType || (exports.UnderwritingResponseType = {}));
