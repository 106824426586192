import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { logger } from '@/utils/logger'

const DISPLAY_DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSSZ'

/**
 * Minimum age for Aven Advisor users.
 */
export const MINIMUM_AGE_YEARS = 18

/**
 * Enforces a format of 2-digit month, 2-digit day, 4-digit year
 */
export const correctDateOfBirthFormat = (dateOfBirthString: string): boolean => {
    const correctlyFormatted = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/.test(dateOfBirthString)
    logger.info(`DoB ${dateOfBirthString} is ${correctlyFormatted ? '' : 'not'} correctly formatted`)
    return correctlyFormatted
}

/**
 * @param dob ISO 8601 format date string. See https://day.js.org/docs/en/parse/string for examples.
 */
export const calculateAgeInWholeYears = (dob: string): number => {
    const today = dayjs()
    const birthDate = dayjs(dob)
    return today.diff(birthDate, 'year')
}

export const ageIsZeroOrNegative = (dob: string): boolean => {
    const applicantAge = calculateAgeInWholeYears(dob)
    logger.info(`ageIsZeroOrNegative calculated age: ${applicantAge}`)
    if (applicantAge <= 0) {
        logger.info(`Applicant age ${applicantAge} is <= 0, which doesn't make sense`)
        return true
    }
    logger.info(`Applicant age ${applicantAge} is > 0`)
    return false
}

export const ageIsBelowMinAge = (dob: string): boolean => {
    const applicantAge = calculateAgeInWholeYears(dob)
    logger.info(`applicantIsBelowMinAge calculated age: ${applicantAge}`)
    if (applicantAge < MINIMUM_AGE_YEARS) {
        logger.info(`Applicant age ${applicantAge} is < min age of ${MINIMUM_AGE_YEARS}`)
        return true
    }
    logger.info(`Applicant age ${applicantAge} is >= min age of ${MINIMUM_AGE_YEARS}`)
    return false
}

export function getCurrentTimezone(): string {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    return dayjs.tz.guess()
}

// Taken from aven-my
export function convertDateTimeFormat(inputDateTime: string | Date, inputTimezone: string, outputTimezone: string, outputFormat: string = DISPLAY_DATE_FORMAT): string {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    if (inputDateTime === null) {
        return ''
    }

    return dayjs.tz(inputDateTime, inputTimezone).tz(outputTimezone).format(outputFormat)
}

/**
 * @param inputDateTime either a ISO 8601 format date string or a Date object. More info: https://day.js.org/docs/en/parse/string.
 * @returns string in the format 'DD MMM YYYY' (e.g. '01 Jan 2022').
 */
export function dateToDayMonthYearString(inputDateTime: string | Date): string {
    if (!inputDateTime) {
        return 'N/A'
    }
    const date = new Date(inputDateTime)
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }
    return date.toLocaleDateString('en-US', options)
}
