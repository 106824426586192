import { httpClient } from '@/utils/http-client'
import { AxiosResponse } from 'axios'
import { DocumentType, getAppInstallId } from '@/nativeWebInteraction/nativeWebInteraction'
import { MinimalContact } from '@/contacts/contacts.types'
import {
    CreditScoreDataPoint,
    GetRecentTransactionsResponse,
    GetSpendingCardDataResponse,
    GetTopMerchantsResponse,
    HomeEquityInfo,
    LienInfo,
    Subscription,
    FetchAvenAdvisorLienReportResponse,
    AvenAdvisorCreditCardRecommendationData,
    GoogleConversionName,
    FacebookPixelStep,
    MethodFiCreditScoreData,
    MethodFiCreditScoreDataPoint,
    AvenAdvisorExpiredPlaidItemsResponse,
    RequiredPlaidAction,
    GetSweepstakesStatusResponse,
    CraigslistListingItem,
    AvenAdvisorPushNotificationCenterItem,
    HomeOwnershipStatus,
    AvenAdvisorLeadAccountInfoPayload,
    MaritalStatus,
    SignUpGoal,
    AvenAdvisorRentalListingResponse,
    AvenAdvisorSaleListingResponse,
    SweepstakesV2,
    SweepstakesV2Poll,
    SweepstakesV2ClaimResult,
    SweepstakesV2AttemptedEntryErrorType,
    PublicSweepstakesV2Result,
    AmazonAffiliateSearchResult,
    AmazonAffiliateSearchRequest,
    AmazonAffiliateLinkRequest,
    FreeCoffeeInfoResponse,
    AvenAdvisorBorrowingCapacityData,
    IDologyResult,
    AvenAffiliateRewardsPointsLedgerItem,
    AvenAffiliateSaleInfo,
    RedeemFreeCoffeeResponse,
} from 'aven_types'
import { logger } from '@/utils/logger'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { AvenAdvisorAddressPayload, AvenAdvisorLeadUpdatedField, NeighborhoodCrimeData, PrefillInformation, StreakResponse } from '@/services/api.types'
import store from '../store'

const fireSessionRequest = (onSuccess: () => void): XMLHttpRequest => {
    const req = new XMLHttpRequest()
    req.onreadystatechange = function () {
        // When request ready + successful
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            const resp = JSON.parse(this.responseText)
            logger.log(`Setting Aven Advisor session info: ${JSON.stringify(resp.payload)}`)
            sessionStorage.setItem(localStorageKey.sessionAccessJWT, resp.payload.jwt.sessionAccessJWT)
            sessionStorage.setItem(localStorageKey.sessionId, resp.payload.sessionId)
            sessionStorage.setItem(localStorageKey.experimentsOverrides, JSON.stringify(resp.payload.experimentsOverrides))
            appSessionStorage.setItem(localStorageKey.experimentsOverrides, JSON.stringify(resp.payload.experimentsOverrides))
            sessionStorage.setItem(localStorageKey.helocCardInfo, JSON.stringify(resp.payload.helocCardInfo))
            sessionStorage.setItem(localStorageKey.autoCardInfo, JSON.stringify(resp.payload.autoCardInfo))
            logger.log(`Finished firing Aven Advisor session request`)
            onSuccess()
        }
        // We abort the request in getSessionInfoWithTimeout() if it takes too long.
        else if (this.readyState === XMLHttpRequest.DONE && this.status === 0) {
            logger.error(`getSessionId request was aborted (usually because the request took too long)`)
        } else if (this.readyState === XMLHttpRequest.DONE) {
            throw new Error(`Failed to get session. XMLHttpRequest.status: ${this.status}`)
        }
    }
    req.open('POST', `${process.env.VUE_APP_API_BASE_URL}/avenAdvisor/session`)
    req.setRequestHeader('Content-Type', 'application/json')

    // window.accessToken is passed from the native app if the user is already logged in
    if (window.accessToken) {
        req.setRequestHeader('Authorization', 'Bearer ' + window.accessToken)
    } else {
        let accessToken
        const jwtTokenJson = store.state.persistedStore.jwtTokens
        try {
            if (jwtTokenJson && typeof jwtTokenJson === 'string') {
                accessToken = JSON.parse(jwtTokenJson).accessJWT
            } else if (jwtTokenJson && jwtTokenJson.accessJWT) {
                accessToken = jwtTokenJson.accessJWT
            }
        } catch (error) {
            logger.info(`unable to parse auth token from persisted store`)
        }

        if (accessToken) {
            req.setRequestHeader('Authorization', 'Bearer ' + accessToken)
        }
    }

    // If we have an access token in the path of the url, we'll set it as the Authorization
    // header so our call to create a session knows which Aven Advisor lead to associate
    // with the session.
    const pathname = new URL(window.location.href).pathname
    const accessJwtMatches = pathname.match('^/a/(.+)')
    // Note that the first index of accessJwtMatches is the full string match. From index
    // 1 onward are the group matches
    if (accessJwtMatches && accessJwtMatches.length > 1) {
        req.setRequestHeader('Authorization', 'Bearer ' + accessJwtMatches[1])
    }

    // window.sessionAccessJWT is passed from the native app if it has already retrieved a session.
    // In that case, this session ID request will simply update the existing session.
    if (window.sessionAccessJWT) {
        req.setRequestHeader('SessionAuthorization', 'Bearer ' + window.sessionAccessJWT)
    }

    const appInstallId = getAppInstallId()

    const body = JSON.stringify({
        avenProperty: 'ADVISOR',
        resolution: window.innerWidth + 'x' + window.innerHeight,
        cookies: document.cookie,
        // Pass raw query string to allow backend to parse however it likes
        queryParams: window.location.search,
        path: window.location.pathname,
        phoneNumber: window.onboardingPhoneNumber,
        deviceGuid: appInstallId,
    })
    logger.log('Requesting session from backend w/ body: ' + body)
    setTimeout(() => {
        req.send(body)
    }, 100)

    return req
}

const getSessionInfo = async () => {
    return new Promise((resolve, reject) => {
        const queryParams = new URLSearchParams(window.location.search)
        const sessionIdReq = fireSessionRequest(() => {
            resolve(undefined)
        })
        setTimeout(function () {
            if (!sessionStorage.getItem(localStorageKey.sessionId)) {
                logger.log('Aven Advisor took too long to load session info, aborting and reloading page')
                sessionIdReq.abort()
                const reloadedQueryParam = queryParams.get('reloaded')
                const timesReloaded = reloadedQueryParam ? parseInt(reloadedQueryParam, 10) : 0
                if (timesReloaded < 3) {
                    logger.log('Force reloading page because session retrieval failed, page already reloaded ' + timesReloaded + ' times')
                    queryParams.set('reloaded', `${timesReloaded + 1}`)
                    window.location.search = queryParams.toString()
                } else {
                    logger.log('Session retrieval failed after three attempts, not reloading page')
                    reject(new Error('Session info request failed after three attempts'))
                }
            }
        }, 5000)
    })
}

interface ResponseData<T> {
    success: boolean
    // Todo Technically payload's type should be `T | null`
    payload: T
    error: string
}

// This is a subset of AppLifecycleEventName in aven_backend/src/entity/appLifecycleEvent.ts
export enum AppLifecycleEventName {
    /** The web app loaded w/ in the Aven Advisor native shell */
    avenAdvisorNativeLoadedWebApp = 'avenAdvisorNativeLoadedWebApp',
    /** The web app initialized Vue (i.e. the web app was ready for the user to interact with) */
    avenAdvisorNativeWebAppInitializedVue = 'avenAdvisorNativeWebAppInitializedVue',
    /** Event measuring the time from app open to Today tab displaying its UI */
    avenAdvisorTimeToInteractive = 'avenAdvisorTimeToInteractive',
}

export const createLifecycleEvent = async (sessionIdentifier: string, eventName: AppLifecycleEventName, eventData: object): Promise<AxiosResponse<void>> => {
    return await httpClient.post('/avenAdvisor/createLifecycleEvent', {
        sessionIdentifier,
        eventName,
        eventData,
    })
}

// Keep in sync with requestTwoFaCode request body in aven_backend/src/models/request/avenAdvisorRequests.ts
export type AvenAdvisorRequestTwoFaPayload =
    | {
          phoneNumber: string
      }
    | { email: string }

// Keep in sync with RequestTwoFaErrors in
// 1. aven_backend/src/controller/avenAdvisorAuthenticationController.ts
// 2. aven_apps/aven_score/src/api/requestTwoFactor.ts
enum RequestTwoFaErrors {
    INVALID_US_PHONE_NUMBER = 'INVALID_US_PHONE_NUMBER',
    INVALID_EMAIL = 'INVALID_EMAIL',
    PHONE_NUMBER_IS_LANDLINE = 'PHONE_NUMBER_IS_LANDLINE',
    TOO_MANY_2FA_REQUESTS = 'TOO_MANY_2FA_REQUESTS',
    UNEXPECTED_TWILIO_RESPONSE = 'UNEXPECTED_TWILIO_RESPONSE',
}

const requestTwoFactor = async (payload: AvenAdvisorRequestTwoFaPayload): Promise<AxiosResponse<ResponseData<{ sid: string }>>> => {
    return await httpClient.post('/avenAdvisor/requestTwoFactorCode', payload)
}

// Keep in sync with AuthFlow in
// 1. aven_backend/src/avenAdvisor/controller/avenAdvisorController.types.ts
// 2. aven_apps/aven_score/src/api/routeToAuthFlow.ts
enum AuthFlow {
    ONBOARDING = 'ONBOARDING',
    SIGN_IN = 'SIGN_IN',
    OG_REJECT_ONBOARDING = 'OG_REJECT_ONBOARDING',
    CONTINUE_ONBOARDING = 'CONTINUE_ONBOARDING',
    PHONE_NUMBER_CHANGE = 'PHONE_NUMBER_CHANGE',
    WEB_ORIGINATION_TO_NATIVE = 'WEB_ORIGINATION_TO_NATIVE',
    // @deprecated
    PASSWORD_RESET = 'PASSWORD_RESET', // This is getting deprecated but keeping it for backward compat
}

export type SessionAuthenticationAuthFlow = Exclude<AuthFlow, AuthFlow.PHONE_NUMBER_CHANGE>

const routeToAuthFlow = async (phoneNumber: string): Promise<AxiosResponse<void>> => {
    return await httpClient.post('/avenAdvisor/routeToAuthFlow', { phoneNumber })
}

export const submitHausQAndAQuestion = async (question: string): Promise<AxiosResponse<ResponseData<{ authFlow: AuthFlow }>>> => {
    return await httpClient.post('/avenAdvisor/addHausQAndAQuestion', { question })
}

// Keep in sync with checkTwoFaCode post body in aven_backend/src/models/request/avenAdvisorRequests.ts
export interface AvenAdvisorCheckTwoFaCodePayload {
    phoneNumber: string
    email: string
    otpSid: string
    otpCode: string
}

const checkTwoFa = async (payload: AvenAdvisorCheckTwoFaCodePayload): Promise<AxiosResponse<ResponseData<{ authFlow: AuthFlow } | { errorText: string }>>> => {
    return await httpClient.post('/avenAdvisor/checkTwoFa', payload)
}

const checkTwoFaForPhoneNumberChange = async (payload: AvenAdvisorCheckTwoFaCodePayload): Promise<AxiosResponse<ResponseData<{ authFlow: AuthFlow } | { errorText: string }>>> => {
    return await httpClient.post('/avenAdvisor/checkTwoFaForPhoneNumberChange', payload)
}

// Keep in sync w/ validateAddress() request body in aven_backend/src/controller/avenAdvisorController.ts
interface AddressPayload {
    addressStreet: string
    secondaryAddressUnit?: string
    addressCity: string
    addressState: string
    addressPostalCode: string
}

export const validateAddress = async (payload: AddressPayload): Promise<AxiosResponse<ResponseData<{ wasCorrected: boolean; validatedAddress: AddressPayload }>>> => {
    return httpClient.post('/avenAdvisor/validateAddress', payload)
}

// Keep in sync with RequestKbaErrors in aven_backend/src/controller/avenAdvisorIdentityVerificationController.ts
export enum RequestKbaErrors {
    REQUEST_FAILED = 'REQUEST_FAILED',
    YOUNGER_THAN_MINIMUM_AGE = 'YOUNGER_THAN_MINIMUM_AGE',
}

// Keep in sync with IDologyQuestion in aven_backend/src/provider/IDology/idologyProvider.ts
interface IDologyQuestion {
    prompt: string
    type: string
    answer: string[]
}

// Keep in sync with payload of requestKbaQuestions() aven_backend/src/controller/avenAdvisorIdentityVerificationController.ts
interface RequestKbQuestionsPayload {
    firstName: string
    lastName: string
    phoneNumber: string
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostalCode: string
    dateOfBirth: Date
    ssnLast4: string
}

export const requestKbaQuestions = async (
    payload: RequestKbQuestionsPayload
): Promise<AxiosResponse<ResponseData<{ idologyQueryId: string; status: IDologyResult; questions: IDologyQuestion[]; idologySsnQueryId: string }>>> => {
    return await httpClient.post('/avenAdvisor/requestKbaQuestions', payload)
}

// Keep in sync with payload of checkKbaQuestionAnswers() & checkKbaChallengeQuestionAnswers() in aven_backend/src/controller/avenAdvisorIdentityVerificationController.ts
interface CheckKbaQuestionAnswersPayload {
    idologyQueryId: string
    answers: { questionType: string; answer: string }[]
}

export const checkKbaQuestionAnswers = async (payload: CheckKbaQuestionAnswersPayload): Promise<AxiosResponse<ResponseData<{ idologyQueryId: string; status: IDologyResult }>>> => {
    return await httpClient.post('/avenAdvisor/checkKbaQuestionAnswers', payload)
}

// Keep in sync with payload of requestKbaChallengeQuestion() aven_backend/src/controller/avenAdvisorIdentityVerificationController.ts
interface RequestKbaChallengeQuestionPayload {
    idologyQueryId: string
}

export const requestKbaChallengeQuestion = async (payload: RequestKbaChallengeQuestionPayload): Promise<AxiosResponse<ResponseData<{ idologyQueryId: string; questions: IDologyQuestion[] }>>> => {
    return await httpClient.post('/avenAdvisor/requestKbaChallengeQuestion', payload)
}

export const checkKbaChallengeQuestionAnswers = async (payload: CheckKbaQuestionAnswersPayload): Promise<AxiosResponse<ResponseData<{ idologyQueryId: string; status: IDologyResult }>>> => {
    return await httpClient.post('/avenAdvisor/checkKbaChallengeQuestionAnswers', payload)
}

export enum HelocHomeAdStatus {
    SHOW_OFFER = 'SHOW_OFFER',
    SHOW_AD = 'SHOW_AD',
    REQUEST_MARITAL_STATUS = 'REQUEST_MARITAL_STATUS',
    GENERATING_OFFER = 'GENERATING_OFFER',
}

const MARITAL_STATUS_TO_TEXT: { [T in MaritalStatus]: string } = {
    [MaritalStatus.MARRIED]: 'Married',
    [MaritalStatus.UNMARRIED]: 'Unmarried',
    [MaritalStatus.SEPARATED]: 'Separated',
}

const HOME_OWNERSHIP_STATUS_TO_TEXT: { [T in HomeOwnershipStatus]: string } = {
    [HomeOwnershipStatus.OWN]: 'I own this home',
    [HomeOwnershipStatus.RENT]: 'I rent',
}

export interface SignDocumentAckMetadataPayload {
    signature: string
    signatureType: string
    localTimestamp: Date // timestamp of when the signature was created in browser's local time using js's Date.toString(): DayOfWeek Month Day Year Hour:Minute:Second GMTOffset (TimeZone)
    browserEvent: string // Serialized browser event that triggered the signature
    urlPath: string
}

export const beginIrsFormVerification = async (metadata: SignDocumentAckMetadataPayload): Promise<AxiosResponse<null>> => {
    return await httpClient.post('/avenAdvisor/beginIrsFormVerification', {
        metadata,
    })
}

export const setStatedIncome = async (statedIncome: number, statedIncomeHousehold: number): Promise<AxiosResponse<null>> => {
    return await httpClient.post('/avenAdvisor/setStatedIncome', {
        statedIncome,
        statedIncomeHousehold,
    })
}

export interface AvenAdvisorCreateAccountPayload extends AvenAdvisorAddressPayload {
    ssnLast4: string
    dateOfBirth: Date
    phoneNumber: string
    email?: string | null
    firstName: string
    lastName: string
    secondaryAddressDesignator?: string | null
    otpSid: string
    otpCode: string
    idologyQueryId: string
    idologySsnQueryId: string
    inviteCode?: string
    consentToCreditOfferDisclosuresHash?: string
    consentToExperianBoostDataSharingHash?: string
    consentToMethodFiTosGitHash?: string
    eSignConsentGitHash?: string
    maritalStatus: MaritalStatus
    isWebOrigination?: boolean
}

// Keep in sync with CreateAccountErrors in aven_backend/src/controller/avenAdvisorController.ts
enum CreateAccountErrors {
    // Purposefully vague so as not to leak account existence.
    CREATING_ACCOUNT_FAILED = 'CREATING_ACCOUNT_FAILED',
    YOUNGER_THAN_MINIMUM_AGE = 'YOUNGER_THAN_MINIMUM_AGE',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    OTP_VERIFICATION_FAILED = 'OTP_VERIFICATION_FAILED',
}

interface JwtTokens {
    accessJWT: string
    refreshJWT: string
    accessExpiry: number
}

interface CreateAccountResponse {
    jwtTokens?: JwtTokens
    reenterInviteCode: boolean
    fullSsnNeeded?: boolean
}

const createAccount = async (payload: AvenAdvisorCreateAccountPayload): Promise<AxiosResponse<ResponseData<CreateAccountResponse>>> => {
    return await httpClient.post('/avenAdvisor/account', payload)
}

// Keep in sync with SignInPayload in aven_backend/src/models/request/avenAdvisorRequests.ts
type AvenAdvisorSignInPayload = { phoneNumber: string; password: string; otpSid: string; otpCode: string } | { email: string; password: string; otpSid: string; otpCode: string }

const signIn = async (payload: AvenAdvisorSignInPayload): Promise<AxiosResponse<ResponseData<{ jwtTokens: JwtTokens }>>> => {
    return await httpClient.post('/avenAdvisor/signIn', payload)
}

// Keep in sync with ResetPasswordErrors in aven_backend/src/controller/avenAdvisorController.ts
enum ResetPasswordErrors {
    INVALID_PASSWORD = 'INVALID_PASSWORD',
}

// Keep in sync with resetPassword() request body in aven_backend/src/controller/avenAdvisorController.ts
interface ResetPasswordPayload {
    phoneNumber?: string
    email?: string
    newPassword: string
    ssnLast4: string
    dateOfBirth: string
    inviteCode?: string
}

const resetPassword = async (payload: ResetPasswordPayload): Promise<AxiosResponse<ResponseData<{ jwtTokens: JwtTokens }>>> => {
    return httpClient.post('/avenAdvisor/resetPassword', payload)
}

export const doesEmailExistForPhoneNumber = async (payload: { phoneNumber: string }): Promise<AxiosResponse<ResponseData<{ emailExists: boolean }>>> => {
    return httpClient.post('/avenAdvisor/doesEmailExistForPhoneNumber', payload)
}

export const checkAccountBlock = async (): Promise<AxiosResponse<ResponseData<{ isAccountBlocked: boolean }>>> => {
    return httpClient.get('/avenAdvisor/checkAccountBlock')
}

export const getAvenAdvisorLeadAccountInfo = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorLeadAccountInfoPayload>>> => {
    return await httpClient.get('/avenAdvisor/accountInfo')
}

export const editAccountInformation = async (
    payload: Record<AvenAdvisorLeadUpdatedField, string>
): Promise<AxiosResponse<ResponseData<{ otpSid: string | null } | { errorCode: RequestTwoFaErrors }>>> => {
    return await httpClient.post('/avenAdvisor/editAccountInformation', payload)
}

// Keep in sync with AvenAdvisorHelocPreQualInfo in aven_backend/src/manager/avenAdvisorHelocCardManager.ts
interface AvenAdvisorHelocPreQualInfo {
    apr?: number
    lineSize?: number
    cashBack?: number
    monthlyFee?: number
    applicationLink: string
}

export const generateHelocCardPreQualOffer = async (): Promise<AxiosResponse<ResponseData<{ preQualInfo: AvenAdvisorHelocPreQualInfo } | null>>> => {
    return await httpClient.get('/avenAdvisor/generateHelocCardPreQualOffer')
}

export const generateLinkToHelocCardPreQualOffer = async (): Promise<AxiosResponse<ResponseData<{ preQualInfo: AvenAdvisorHelocPreQualInfo } | null>>> => {
    return await httpClient.get('/avenAdvisor/generateLinkToHelocCardPreQualOffer', { timeout: 70000 })
}

export const setMaritalStatusToAdvisorLead = async (maritalStatus: MaritalStatus): Promise<AxiosResponse<ResponseData<{}>>> => {
    return await httpClient.post('/avenAdvisor/setMaritalStatus', { maritalStatus })
}

export const generateLinkToAutoApplication = async (): Promise<AxiosResponse<ResponseData<{ autoApplicationUrl: string }>>> => {
    return await httpClient.get('/avenAdvisor/generateLinkToAutoApplication')
}

// Keep in sync with GetAvenAdvisorCreditScoresErrors in aven_backend/src/avenAdvisor/controller/avenAdvisorCreditReportController.ts
export enum GetAvenAdvisorCreditScoresErrors {
    NEED_FULL_9_SSN = 'NEED_FULL_9_SSN',
    REPORT_FETCH_ERROR = 'REPORT_FETCH_ERROR',
    EXPERIAN_FROZEN_ERROR = 'EXPERIAN_FROZEN_ERROR',
    NO_SCORE_AVAILABLE_ERROR = 'NO_SCORE_AVAILABLE_ERROR',
    USER_IS_A_MINOR = 'USER_IS_A_MINOR',
    USER_IS_BLOCKED = 'USER_IS_BLOCKED',
}

// remove history?, kept for backcompat
export const getCreditScores = async (): Promise<
    AxiosResponse<ResponseData<{ ficoScore: number | null; vantageScore: number | null; dateOfScores: Date; dateOfNextScores: Date; history?: CreditScoreDataPoint[] }>>
> => {
    return await httpClient.get('/avenAdvisor/creditScores')
}

export const intializeMethodFiCreditScoreData = async (): Promise<AxiosResponse<ResponseData<{}>>> => {
    return await httpClient.post('/avenAdvisor/initializeMethodFiCreditScore')
}

export const getMethodFiCreditScoreData = async (allowCachedResult?: boolean): Promise<AxiosResponse<ResponseData<MethodFiCreditScoreData | null>>> => {
    return await httpClient.get('/avenAdvisor/getMethodFiCreditScore', { params: { allowCachedResult } })
}

export const getCreditScoreHistory = async (): Promise<AxiosResponse<ResponseData<{ history: CreditScoreDataPoint[] }>>> => {
    return await httpClient.get('/avenAdvisor/creditScoreHistory')
}

export const getMethodFiCreditScoreHistory = async (): Promise<AxiosResponse<ResponseData<{ history: MethodFiCreditScoreDataPoint[] }>>> => {
    return await httpClient.get('/avenAdvisor/methodFiCreditScoreHistory')
}

const getTotalDebt = async () => {
    return await httpClient.get('/avenAdvisor/getTotalDebt')
}

// Keep in sync with AvmAndHomeEquity in aven_backend/src/controller/avenAdvisorController.ts
// currently not in use on front-end. can delete?
// interface AvmAndHomeEquity {
//     avm: number | null
//     homeEquity: number | null
// }

// Keep in sync with BasePlaidInstitutionInfo in aven_backend/src/manager/avenAdvisorNetWorthManager.types.ts
interface BasePlaidInstitutionInfo {
    institutionId: string | null
    institutionName: string | null
    accounts: { assets: AvenAdvisorNetWorth[] }
    totalAssets: number
}

// Keep in sync with MinimalTransaction in aven_backend/src/manager/avenAdvisorNetWorthManager.types.ts
interface MinimalTransaction {
    transactionId: string
    name: string
    amount: number
    dateStr: string
    merchantLogoUrl?: string
}

// Keep in sync with EnhancedAdvisorPlaidInstitutionInfo in aven_backend/src/manager/avenAdvisorNetWorthManager.types.ts
interface EnhancedAdvisorPlaidInstitutionInfo extends BasePlaidInstitutionInfo {
    requiredPlaidAction: RequiredPlaidAction | null
    lastUpdatedDate: Date
    nextUpdateAvailableDate: Date
    lowBalanceAlertThreshold: number | null
    recentInflows: MinimalTransaction[]
    history: { value: number; date: Date }[]
}

// Keep in sync with AvenAdvisorNetWorthResponse in aven_frontend/aven_advisor/src/services/api.ts
export type AvenAdvisorNetWorthResponse = { institutions: EnhancedAdvisorPlaidInstitutionInfo[]; aggregatedHistory: { value: number; date: Date }[] }

// Keep in sync with AvenAdvisorNetWorth in aven_backend/src/avenAdvisor/manager/avenAdvisorNetWorthManager.types.ts
export interface AvenAdvisorNetWorth {
    balance: number
    accountName: string
    accountNumberMask: string | null
}

export interface AssetSnapshot {
    date: Date
    value: number
}

let getCashAndStockPromise: Promise<AxiosResponse<ResponseData<AvenAdvisorNetWorthResponse>>> | null = null

export const getCashAndStock = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorNetWorthResponse>>> => {
    if (!getCashAndStockPromise) {
        logger.info('Fetching cash and stock')
        getCashAndStockPromise = httpClient.get('/avenAdvisor/getCashAndStock')
        getCashAndStockPromise.finally(() => {
            getCashAndStockPromise = null
        })
    }
    logger.info('Returning cash and stock promise')
    return await getCashAndStockPromise
}

// Keep in sync with AvenAdvisorHomeValueResponse in aven_backend/src/manager/avenAdvisorNetWorthManager.ts
export interface AvenAdvisorHomeValueResponse {
    nextUpdateAvailableDate: Date
    lastUpdatedDate: Date
    homeValue: number
    history: HomeValueSnapshot[]
}

let getHomeValuePromise: Promise<AxiosResponse<ResponseData<AvenAdvisorHomeValueResponse | null>>> | null = null

export const getHomeValue = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorHomeValueResponse | null>>> => {
    if (!getHomeValuePromise) {
        logger.info('Fetching home value')
        getHomeValuePromise = httpClient.get('/avenAdvisor/homeValue')
        getHomeValuePromise.finally(() => {
            getHomeValuePromise = null
        })
    }
    logger.info('Returning home value promise')
    return await getHomeValuePromise
}

// Keep in sync with HomeValueSnapshot in aven_backend/src/manager/avenAdvisorNetWorthManager.ts
export interface HomeValueSnapshot {
    dateOfHomeValue: Date
    homeValueDollars: number
}

export interface GetHomeOwnershipStatusResponse {
    homeOwnershipStatus: HomeOwnershipStatus
}

let getHomeOwnershipStatusPromise: Promise<AxiosResponse<ResponseData<GetHomeOwnershipStatusResponse>>> | null = null

export const getHomeOwnershipStatus = async (): Promise<AxiosResponse<ResponseData<GetHomeOwnershipStatusResponse>>> => {
    if (!getHomeOwnershipStatusPromise) {
        logger.info('Fetching home ownership status')
        getHomeOwnershipStatusPromise = httpClient.get('/avenAdvisor/homeOwnershipStatus')
        getHomeOwnershipStatusPromise.finally(() => {
            getHomeOwnershipStatusPromise = null
        })
    }
    logger.info('Returning home ownership status promise')
    return await getHomeOwnershipStatusPromise
}

export interface NeighborhoodDataResponse {
    medianSalePrice: number
    lastUpdatedAt: Date
    history: {
        medianSalePrice: number
        date: Date
    }[]
}

let getNeighborhoodReportPromise: Promise<AxiosResponse<ResponseData<NeighborhoodDataResponse>>> | null = null

export const getNeighborhoodReport = async (): Promise<AxiosResponse<ResponseData<NeighborhoodDataResponse>>> => {
    if (!getNeighborhoodReportPromise) {
        logger.info('Fetching neighborhood report')
        getNeighborhoodReportPromise = httpClient.get('/avenAdvisor/getNeighborhoodReport')
        getNeighborhoodReportPromise.finally(() => {
            getNeighborhoodReportPromise = null
        })
    }
    logger.info('Returning neighborhood report promise')
    return await getNeighborhoodReportPromise
}

// Keep in sync with AvenAdvisorRentalMarketData in aven_backend/src/avenAdvisor/manager/avenAdvisorRentcastManager.ts
export interface AvenAdvisorRentalMarketData {
    averageRent: number
    lastUpdatedAt: Date
    numberOfBedrooms: string | null
    history: {
        averageRent: number
        date: Date
    }[]
}

let getNeighborhoodRentPricesPromise: Promise<AxiosResponse<ResponseData<AvenAdvisorRentalMarketData | null>>> | null = null

export const getNeighborhoodRentPrices = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorRentalMarketData | null>>> => {
    if (!getNeighborhoodRentPricesPromise) {
        logger.info('Fetching neighborhood rent prices')
        getNeighborhoodRentPricesPromise = httpClient.get('/avenAdvisor/getNeighborhoodRentPrices')
        getNeighborhoodRentPricesPromise.finally(() => {
            getNeighborhoodRentPricesPromise = null
        })
    }
    logger.info('Returning neighborhood rent prices promise')
    return await getNeighborhoodRentPricesPromise
}

let getSaleListingsPromise: Promise<AxiosResponse<ResponseData<AvenAdvisorSaleListingResponse | null>>> | null = null

export const getSaleListings = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorSaleListingResponse | null>>> => {
    if (!getSaleListingsPromise) {
        logger.info('Fetching sale listings')
        getSaleListingsPromise = httpClient.get('/avenAdvisor/getSaleListings')
        getSaleListingsPromise.finally(() => {
            getSaleListingsPromise = null
        })
    }
    logger.info('Returning sale listings promise')
    return await getSaleListingsPromise
}

let getRentListingsPromise: Promise<AxiosResponse<ResponseData<AvenAdvisorRentalListingResponse | null>>> | null = null

export const getRentListings = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorRentalListingResponse | null>>> => {
    if (!getRentListingsPromise) {
        logger.info('Fetching rent listings')
        getRentListingsPromise = httpClient.get('/avenAdvisor/getRentalListings')
        getRentListingsPromise.finally(() => {
            getRentListingsPromise = null
        })
    }
    logger.info('Returning rent listings promise')
    return await getRentListingsPromise
}

const getInviteCode = async () => {
    return await httpClient.get('/avenAdvisor/getInviteCode')
}

export const requestPlaidLinkToken = async (): Promise<AxiosResponse<ResponseData<{ linkToken: string }>>> => {
    return await httpClient.get('/avenAdvisor/requestPlaidToken')
}

export const requestPlaidLinkUpdateToken = async (institutionId: string): Promise<AxiosResponse<ResponseData<{ linkUpdateToken: string }>>> => {
    return await httpClient.get('/avenAdvisor/requestPlaidUpdateToken', { params: { institutionId } })
}

const plaidReportFetchState = async (itemId: string) => {
    return await httpClient.get('/avenAdvisor/plaidReportFetchState', { params: { itemId } })
}

// institutionInfo = institutionName
const startPlaidReportFetch = async (plaidPublicToken: string, institutionId?: string) => {
    return await httpClient.post('/avenAdvisor/startPlaidReportFetch', {
        publicToken: plaidPublicToken,
        institutionId,
    })
}

export const getSuccessfulSignOnBonusCount = async (): Promise<AxiosResponse<ResponseData<{ numberOfBonusesPaid: number; maxReferralsPerMonth: number }>>> => {
    return await httpClient.get('/avenAdvisor/getSuccessfulSignOnBonusCount')
}

export const getLegalDocument = async (docType: DocumentType): Promise<AxiosResponse> => {
    return await httpClient.get('/avenAdvisor/legal', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

export const get4506cDocumentWithSsn = async (ssn: string): Promise<AxiosResponse<ResponseData<{ htmlString: string }>>> => {
    return await httpClient.post('/avenAdvisor/get4506cDocumentWithSsn', {
        ssn,
    })
}

const updateSocialSecurity = async (ssn: string) => {
    return await httpClient.post('/avenAdvisor/updateSsn', {
        ssn: ssn,
    })
}

export const getReferralInfo = async (): Promise<
    AxiosResponse<
        ResponseData<{
            pendingReferralDollars: number
            payoutsEnabled: boolean
            remediationMessage?: string
            spinToWinEnabled: boolean
            availableSpins: number
            lastSuccessfulSpinAmount: number | null
        }>
    >
> => {
    return await httpClient.get('/avenAdvisor/getReferralInfo')
}

export const spinTheWheel = async (): Promise<AxiosResponse<ResponseData<{ dollarAmount: number }>>> => {
    return await httpClient.get('/avenAdvisor/spinTheWheel')
}

export const newSpinForAvenEmployee = async (): Promise<AxiosResponse<ResponseData<undefined>>> => {
    return await httpClient.get('/avenAdvisor/newSpinForAvenEmployee')
}

let getAvenAdDisplayInfoPromise: Promise<
    AxiosResponse<ResponseData<{ canDisplayHelocCardAd: boolean; isUserAlreadyHelocCardApplicant: boolean; canDisplayAutoAd: boolean; linkToHelocCardPreQualOffer?: string }>>
> | null = null

export const getAvenAdDisplayInfo = async (): Promise<
    AxiosResponse<ResponseData<{ canDisplayHelocCardAd: boolean; isUserAlreadyHelocCardApplicant: boolean; canDisplayAutoAd: boolean; linkToHelocCardPreQualOffer?: string }>>
> => {
    if (!getAvenAdDisplayInfoPromise) {
        logger.info('Fetching ad display info')
        getAvenAdDisplayInfoPromise = httpClient.get('/avenAdvisor/getAvenAdDisplayInfo')
        getAvenAdDisplayInfoPromise.finally(() => {
            getAvenAdDisplayInfoPromise = null
        })
    }
    logger.info('Returning ad display info promise')
    return await getAvenAdDisplayInfoPromise
}

// Keep in sync with GetPayoutsAccountSetUpLinkErrors in aven_backend/src/controller/avenAdvisorController.ts
enum GetPayoutsAccountSetUpLinkErrors {
    FAILED_TO_CREATE_CONNECTION = 'FAILED_TO_CREATE_CONNECTION',
}

const getPayoutsAccountSetUpLink = async (): Promise<AxiosResponse<ResponseData<{ accountSetUpLink?: string }>>> => {
    return await httpClient.get('/avenAdvisor/payoutsAccountSetUpLink')
}

export const saveContacts = async (contacts: MinimalContact[]) => {
    return await httpClient.post('/avenAdvisor/saveContacts', { contacts })
}

export const getContactsAlreadyOnAdvisor = async (): Promise<AxiosResponse<ResponseData<{ existingUsersPhoneNumbers: string[]; existingUsersEmails: string[] }>>> => {
    return await httpClient.get('/avenAdvisor/getContactsAlreadyOnAdvisor')
}

export const unlinkPlaidForCurrentUser = async (
    phoneNumber: string
): Promise<AxiosResponse<ResponseData<{ successfullyUnlinkedAvenAdvisorLeadIds: number[]; failedtoUnlinkAvenAdvisorLeadIds: number[] }>>> => {
    return await httpClient.post('/avenAdvisor/ops/unlinkPlaidReportsForPhoneNumber', { phoneNumber })
}

const initiateCreditOffer = async (fcraLanguage: string, tcpaLanguage: string): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/initiateCreditOfferCreation', { fcraLanguage, tcpaLanguage })
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_ProductType
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
enum ProductType {
    credit_card = 'credit_card',
    insurance = 'insurance',
    life_insurance = 'life_insurance',
    loan = 'loan',
    mortgage = 'mortgage',
    savings = 'savings',
    other = 'other',
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_ProductSubType
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
enum ProductSubType {
    credit_card = 'credit_card',
    secured_card = 'secured_card',
    personal_loan = 'personal_loan',
    secured_loan = 'secured_loan',
    student_loan_refinance = 'student_loan_refinance',
    co_applicant_loan = 'co_applicant_loan',
    line_of_credit = 'line_of_credit',
    purchase = 'purchase',
    refinance = 'refinance',
    savings_account = 'savings_account',
    money_market_account = 'money_market_account',
    certificate_of_deposit = 'certificate_of_deposit',
    individual_retirement_account = 'individual_retirement_account',
    cash_management_account = 'cash_management_account',
    high_interest_checking = 'high_interest_checking',
    accidental_death_benefits = 'accidental_death_benefits',
    term_life = 'term_life',
    term_life_instant = 'term_life_instant',
    whole_life = 'whole_life',
    debt_relief = 'debt_relief',
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_OriginatorImage
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
interface OriginatorImage {
    sizeKey: string // e.g. "120x40"
    url: string
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_Originator
 * There are other fields, but we don't need them right now
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
interface Originator {
    name: string
    description: string
    images: OriginatorImage[]
    disclaimer: string
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_TermUnit
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
enum TermUnit {
    day = 'day',
    month = 'month',
    year = 'year',
    open = 'open',
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_AprType
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
enum AprType {
    variable = 'variable',
    regular = 'regular',
    fixed = 'fixed',
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_LoanOffer
 * There are many more fields, but we don't need them right now
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
export interface LoanOffer {
    originator: Originator
    productType: ProductType
    productSubType: ProductSubType
    productSubTypeDisclaimer?: string
    url: string
    preQualified: boolean
    preApproved: boolean
    secured: boolean
    maxAmount: number
    minAmount?: number
    termLength: number
    termUnit: TermUnit
    displayTermUnit?: string
    termDescription?: string
    maxApr?: number
    minApr?: number
    meanApr?: number
    aprType: AprType
    aprDescription?: string
    feeRate?: number
    maxFeeRate?: number
    minFeeRate?: number
    feeFixed?: number
    maxFeeFixed?: number
    minFeeFixed?: number
    allowPrepayment: boolean
    prepaymentFee?: number
    monthlyPayment?: number
    maxMonthlyPayment?: number
    minMonthlyPayment?: number
    monthlyPaymentDescription?: string
    meanMonthlyPayment?: number
    maxTotalPayment?: number
    minTotalPayment?: number
    meanTotalPayment?: number
    recommendationScore?: number
    terms?: string
}

/**
 * https://evenfinancial.com/docs/api-reference/#tocS_SpecialOffer
 */
// Keep in sync with aven_backend/src/manager/evenFinancialManager.ts
export interface SpecialOffer {
    uuid: string
    name: string
    desc: string
    url: string
    partnerName: string
    partnerImageUrl: string
    recommendationScore: number
    payout: number
    productSubType: ProductSubType
}

// Keep in sync with pollForCreditOffers() in aven_backend/src/controller/avenAdvisorController.ts
type PollForCreditOffersResponse = null | { inProgress: boolean; offers: LoanOffer[]; specialOffers: SpecialOffer[] }

const pollForCreditOffers = async (): Promise<AxiosResponse<ResponseData<PollForCreditOffersResponse>>> => {
    return await httpClient.get('/avenAdvisor/pollForCreditOffers')
}

// Keep in sync with return value of getIllustrativeSavingsExample in aven_backend/src/controller/avenAdvisorController.ts
interface IllustrativeSavingsExampleResponse {
    lineSize: number
    baseline: {
        apr: number
        aprSource: string | null
        firstPayment: number
        numPeriods: number
        totalPaid: number
        totalPayments: number
    }
    comparison: {
        apr: number
        firstPayment: number
        numPeriods: number
        totalPaid: number
        totalPayments: number
    }
}

const getIllustrativeSavingsExample = async (comparisonApr: number): Promise<AxiosResponse<ResponseData<IllustrativeSavingsExampleResponse>>> => {
    return await httpClient.post('/avenAdvisor/getIllustrativeSavingsExample', { comparisonApr })
}

export const consentToShareDataWithExperianBoost = async (payload: { codeHash: string }): Promise<AxiosResponse<ResponseData<null>>> => {
    // Keep payload in sync with consentToShareDataWithExperianBoost() in aven_backend/src/controller/avenAdvisorExperianBoostController.ts
    return await httpClient.post('/avenAdvisor/consentToShareDataWithExperianBoost', payload)
}

export const getConsentToShareDataWithExperianBoost = async (): Promise<AxiosResponse<ResponseData<{ hash: string | null }>>> => {
    return await httpClient.get('/avenAdvisor/getConsentToShareDataWithExperianBoost')
}

export const acceptCreditOfferDisclosure = async (payload: { codeHash: string }): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/acceptCreditOfferDisclosure', payload)
}

export const acceptEsignConsent = async (payload: { codeHash: string }): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/acceptEsignConsent', payload)
}

export const consentToContactUpload = async (payload: { codeHash: string }): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/consentToContactUpload', payload)
}

export const getIsPlaidRequiredForPaidReferral = async (): Promise<AxiosResponse<ResponseData<{ isPlaidRequiredForPaidReferral: boolean }>>> => {
    return await httpClient.get('/avenAdvisor/getIsPlaidRequiredForPaidReferral')
}

// Keep in sync with StartExperianBoostErrors in aven_backend/src/controller/avenAdvisorExperianBoostController.ts
export enum StartExperianBoostErrors {
    MISSING_CONSENT_TO_DATA_SHARING = 'MISSING_CONSENT_TO_DATA_SHARING',
    CLIENT_ACCESS_TOKEN_GENERATION_FAILED = 'CLIENT_ACCESS_TOKEN_GENERATION_FAILED',
    CUSTOMER_CREATION_FAILED = 'CUSTOMER_CREATION_FAILED',
    PARTNER_LINK_TOKEN_GENERATION_FAILED = 'PARTNER_LINK_TOKEN_GENERATION_FAILED',
    PARTNER_REDIRECT_URL_GENERATION_FAILED = 'PARTNER_REDIRECT_URL_GENERATION_FAILED',
    USER_DATE_OF_BIRTH_MISSING = 'USER_DATE_OF_BIRTH_MISSING',
}

export const startExperianBoost = async (): Promise<AxiosResponse<ResponseData<{ redirectUrl?: string }>>> => {
    return await httpClient.get('/avenAdvisor/startExperianBoost')
}

export const setDateOfBirth = async (dateOfBirth: string): Promise<AxiosResponse<ResponseData<any>>> => {
    return await httpClient.post('/avenAdvisor/setDateOfBirth', { dateOfBirth })
}

export const setEmailAndSignUpGoal = async (email: string, signUpGoal: SignUpGoal | null): Promise<AxiosResponse<ResponseData<any>>> => {
    return await httpClient.post('/avenAdvisor/setEmailAndSignUpGoal', { email, signUpGoal })
}

// Keep in sync with GetHeiEstimateResponse in aven_backend/src/controller/avenAdvisorPointController.ts
interface GetHeiEstimateResponse {
    applicationUrl: string
    minEstimateOfferAmountDollars: number
    maxEstimateOfferAmountDollars: number
}

export const getHeiEstimate = async (): Promise<AxiosResponse<ResponseData<GetHeiEstimateResponse>>> => {
    return await httpClient.get('/avenAdvisor/getHeiEstimate')
}

// Keep in sync with MethodFiLiabilityAccountType in aven_backend/src/provider/MethodFi/methodFiProvider.types.ts
export enum MethodFiLiabilityAccountType {
    student_loan = 'student_loan',
    credit_card = 'credit_card',
    auto_loan = 'auto_loan',
    mortgage = 'mortgage',
    personal_loan = 'personal_loan',
    loan = 'loan',
    unknown = 'unknown',
}

// Keep in sync with AdvisorLiabilityAccountInformation in aven_backend/src/manager/personalFinancialLiabilityManager.ts
export interface AdvisorLiabilityAccountInformation {
    liabilityType: MethodFiLiabilityAccountType
    balanceCents: number
    originalLoanAmountCents?: number
    creditLimitCents?: number
    accountNumberMask: string
    accountName: string
    institutionIconUrl: string
    lastUpdatedDate: Date
    isStale?: boolean
    liabilityId: number // This is the id of the PersonalFinancialLiability
    onTimePaymentMonths?: number
}

export interface AvenAdvisorLiabilitySnapshot {
    balanceCents: number
    updatedDate: Date
}

let getLiabilityAccountsPromise: Promise<AxiosResponse<ResponseData<{ liabilityAccounts: AdvisorLiabilityAccountInformation[]; liabilityAccountsHistory: AvenAdvisorLiabilitySnapshot[] }>>> | null =
    null

export const getLiabilityAccounts = async (payload: {
    codeHash: string
}): Promise<AxiosResponse<ResponseData<{ liabilityAccounts: AdvisorLiabilityAccountInformation[]; liabilityAccountsHistory: AvenAdvisorLiabilitySnapshot[] }>>> => {
    if (!getLiabilityAccountsPromise) {
        logger.info('Fetching liability accounts')
        getLiabilityAccountsPromise = httpClient.post('/avenAdvisor/getLiabilityAccounts', payload)
        getLiabilityAccountsPromise.finally(() => {
            getLiabilityAccountsPromise = null
        })
    }
    logger.info('Returning liability accounts promise')
    return await getLiabilityAccountsPromise
}

// Keep in sync with AvenAdvisorConfig in aven_backend/src/manager/avenAdvisorConfigManager.ts
export interface AvenAdvisorConfig {
    hasOpenedAppAtLeastOnceBefore: boolean
    didAcceptMethodFiTos: boolean
    didAcceptCreditOfferDisclosure: boolean
    didAcceptEsignConsent: boolean
    didConsentToContactUpload: boolean
}

let getConfigPromise: Promise<AxiosResponse<ResponseData<{ config: AvenAdvisorConfig }>>> | null = null

export const getConfig = async (): Promise<AxiosResponse<ResponseData<{ config: AvenAdvisorConfig }>>> => {
    if (!getConfigPromise) {
        logger.info('Fetching config')
        getConfigPromise = httpClient.get('/avenAdvisor/config')
        getConfigPromise.finally(() => {
            getConfigPromise = null
        })
    }
    logger.info('Returning config promise')
    return await getConfigPromise
}

export const setHomeownerStatusForEmployees = async (payload: { homeOwnershipStatus: HomeOwnershipStatus }): Promise<AxiosResponse<ResponseData<null>>> => {
    return httpClient.post('/avenAdvisor/setHomeownerStatusForEmployees', payload)
}

// Keep in sync with AvenAdvisorEnergyPrices in aven_backend/src/manager/avenAdvisorEnergyPriceManager.ts
export interface AvenAdvisorEnergyPrices {
    currentHouseholdElectricityCost: number | undefined
    currentHouseholdNaturalGasCost: number | undefined
    energyPriceHistory: { value: number; date: Date }[]
    lastUpdated: Date
    nextUpdate: Date
}

export interface AlpacaEquityWrapperResponse {
    stockBars: Record<string, AlpacaEquityWrapper> | null
}
export interface AlpacaEquityWrapper {
    bars: AlpacaBar[]
    priorClosePrice: number
    todayClosePrice: number
    fullName: string
}
export interface AlpacaBar {
    Symbol: string | undefined
    ClosePrice: number | undefined
    Volume: number | undefined
    Timestamp: string | undefined
}

let getEnergyPricesPromise: Promise<AxiosResponse<ResponseData<AvenAdvisorEnergyPrices | null>>> | null = null

export const getEnergyPrices = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorEnergyPrices | null>>> => {
    if (!getEnergyPricesPromise) {
        logger.info('Fetching energy prices')
        getEnergyPricesPromise = httpClient.get('/avenAdvisor/getEnergyPrices')
        getEnergyPricesPromise.finally(() => {
            getEnergyPricesPromise = null
        })
    }
    logger.info('Returning energy prices promise')
    return await getEnergyPricesPromise
}

let getStockBarsPromise: Promise<AxiosResponse<ResponseData<AlpacaEquityWrapperResponse | null>>> | null = null

export const getStockBars = async (): Promise<AxiosResponse<ResponseData<AlpacaEquityWrapperResponse | null>>> => {
    if (!getStockBarsPromise) {
        logger.info('Fetching stock bars')
        getStockBarsPromise = httpClient.get('/avenAdvisor/getStockBars')
        getStockBarsPromise.finally(() => {
            getStockBarsPromise = null
        })
    }
    logger.info('Returning stock bars promise')
    return await getStockBarsPromise
}

export interface VehicleInformation {
    valuation: number
    valuationDate: Date
    nextUpdateAvailableDate: Date
    make: string
    model: string
    year: number
    history: { value: number; date: Date }[]
}
export const retrieveVehicleValue = async (): Promise<AxiosResponse<ResponseData<VehicleInformation | null>>> => {
    return await httpClient.get('/avenAdvisor/retrieveVehicleValue')
}

export const createVehicleIfNotExists = async (licensePlateState: string, licensePlate: string): Promise<AxiosResponse<ResponseData<VehicleInformation | null>>> => {
    return await httpClient.post('/avenAdvisor/createVehicleIfNotExists', { licensePlateState, licensePlate })
}

export const getTopMerchants = async (): Promise<AxiosResponse<ResponseData<GetTopMerchantsResponse>>> => {
    return await httpClient.get('/avenAdvisor/getTopMerchants')
}

export interface GetSubscriptionsResponse {
    nextUpdateAvailableDate: Date | null
    lastUpdatedDate: Date | null
    subscriptions: Subscription[]
    institutionNames: string[]
}

// Keep in sync with aven_backend/src/controller/avenAdvisorWeeklyTransactionsController.ts
export interface GetWeeklyTransactionsResponse {
    transactions: MinimalTransaction[]
    institutionNames: string[]
    snapshotDate: Date
    weeklySpending?: { lastDayOfWeek: Date; spending: number }[]
}

export const DEFAULT_NUM_WEEKLY_TRANSACTIONS_TO_RETURN = 20
export const DEFAULT_NUM_OF_DAYS_TO_RETURN_RECENT_TRANSACTIONS = 7

export const getWeeklyTransactions = async (numTransactionsToReturn: number): Promise<AxiosResponse<ResponseData<GetWeeklyTransactionsResponse>>> => {
    return await httpClient.get('/avenAdvisor/getWeeklyTransactions', { params: { numTransactionsToReturn } })
}

export const getRecentTransactions = async (lastNDays: number): Promise<AxiosResponse<ResponseData<GetRecentTransactionsResponse>>> => {
    return await httpClient.get('/avenAdvisor/getRecentTransactions', { params: { lastNDays } })
}

let getTransactionsBetweenDatesPromise: Promise<AxiosResponse<ResponseData<GetRecentTransactionsResponse>>> | null = null

export const getTransactionsBetweenDates = async (startDate: string, endDate: string): Promise<AxiosResponse<ResponseData<GetRecentTransactionsResponse>>> => {
    if (!getTransactionsBetweenDatesPromise) {
        logger.info('Fetching transactions between dates')
        getTransactionsBetweenDatesPromise = httpClient.get('/avenAdvisor/getTransactionsBetweenDates', { params: { startDate, endDate } })
        getTransactionsBetweenDatesPromise.finally(() => {
            getTransactionsBetweenDatesPromise = null
        })
    }
    logger.info('Returning transactions between dates promise')
    return await getTransactionsBetweenDatesPromise
}

let getNotificationDataWithPushIdPromise: Promise<AxiosResponse<ResponseData<AvenAdvisorPushNotificationCenterItem | null>>> | null = null

export const getNotificationDataWithPushId = async (pushId: string, enforceLatest: boolean = false): Promise<AxiosResponse<ResponseData<AvenAdvisorPushNotificationCenterItem | null>>> => {
    if (!getNotificationDataWithPushIdPromise) {
        logger.info('Fetching notification data with push id')
        getNotificationDataWithPushIdPromise = httpClient.get('/avenAdvisor/getNotificationDataWithPushId', { params: { pushId: +pushId, enforceLatest } })
        getNotificationDataWithPushIdPromise.finally(() => {
            getNotificationDataWithPushIdPromise = null
        })
    }
    logger.info('Returning notification data with push id promise')
    return await getNotificationDataWithPushIdPromise
}

export const getSpendingCardData = async (): Promise<AxiosResponse<ResponseData<GetSpendingCardDataResponse>>> => {
    return await httpClient.get('/avenAdvisor/getSpendingCardData')
}

export const getSubscriptions = async (): Promise<AxiosResponse<ResponseData<GetSubscriptionsResponse>>> => {
    return await httpClient.get('/avenAdvisor/getSubscriptions')
}

export const updateHomeAddressAndOwnershipStatus = async (updatePayload: AvenAdvisorAddressPayload): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/updateHomeAddressAndOwnershipStatus', updatePayload)
}

export const stockSearch = async (searchTerm: string, limit: number): Promise<AxiosResponse<ResponseData<Record<string, AlpacaEquityWrapper> | null>>> => {
    return await httpClient.get('/avenAdvisor/stockSearch', {
        params: {
            input: searchTerm,
            limit,
        },
    })
}

export const setEquitiesTickers = async (newTickers: string[]): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/setEquitiesTickers', { newTickers })
}

// Keep in sync with ReferralDepositFunnelState in aven_backend/src/controller/avenAdvisorReferralDepositFunnelState.ts
export enum ReferralDepositFunnelState {
    PLAID_CONNECT_NULL_STATE = 'PLAID_CONNECT_NULL_STATE',
    PLAID_CONNECT_STARTED = 'PLAID_CONNECT_STARTED',
    PLAID_CONNECTED_SUCCESS = 'PLAID_CONNECTED_SUCCESS',
    PLAID_CONNECTED_FAILURE = 'PLAID_CONNECTED_FAILURE',
    PLAID_CONNECTED_SUCCESS_WITH_ACCOUNTS = 'PLAID_CONNECTED_SUCCESS_WITH_ACCOUNTS',
}

export const setReferralDepositFunnelState = async (funnelState: ReferralDepositFunnelState): Promise<AxiosResponse<ResponseData<null>>> => {
    // Keep payload in sync with setReferralDepositFunnelState() in aven_backend/src/controller/avenAdvisorReferralDepositFunnelState.ts
    return await httpClient.post('/avenAdvisor/setReferralDepositFunnelState', { funnelState: funnelState })
}

export interface ReferralDepositFunnelStateResponse {
    currentFunnelState: ReferralDepositFunnelState
}

export const getReferralDepositFunnelState = async (): Promise<AxiosResponse<ResponseData<ReferralDepositFunnelStateResponse>>> => {
    return await httpClient.get('/avenAdvisor/getReferralDepositFunnelState')
}

export const deleteCurrentAccount = async (accountDeleteReason: string): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/deleteAccount', { accountDeleteReason })
}

export const getSweepstakesStatus = async (): Promise<AxiosResponse<ResponseData<GetSweepstakesStatusResponse | null>>> => {
    return await httpClient.get('/avenAdvisor/getSweepstakesStatus')
}

export const getSweepstakesV2 = async (): Promise<AxiosResponse<ResponseData<SweepstakesV2 | null>>> => {
    return await httpClient.get('/avenAdvisor/getSweepstakesV2')
}

export const enterSweepstakesV2 = async (): Promise<AxiosResponse<ResponseData<{ error: SweepstakesV2AttemptedEntryErrorType | null }>> | null> => {
    return await httpClient.post('/avenAdvisor/enterSweepstakesV2')
}

export const updateSweepstakesV2Heartbeat = async (): Promise<AxiosResponse<ResponseData<null>> | null> => {
    return await httpClient.post('/avenAdvisor/updateSweepstakesV2Presence')
}

export const pollForSweepstakesV2Winner = async (): Promise<AxiosResponse<ResponseData<SweepstakesV2Poll>> | null> => {
    return await httpClient.get('/avenAdvisor/pollSweepstakesV2Winner')
}

export const claimPrizeIfSweepstakesV2Winner = async (): Promise<AxiosResponse<ResponseData<SweepstakesV2ClaimResult>> | null> => {
    return await httpClient.get('/avenAdvisor/claimSweepstakesV2Prize')
}

export const getCreditScoreTips = async (forceRefresh = false): Promise<AxiosResponse<ResponseData<{ tips: { tipTitle: string; tipBody: string }[] } | null>>> => {
    return await httpClient.post('/avenAdvisor/getCreditScoreTips', { forceRefresh })
}

export const getPrefillInformation = async (phoneNumber: string, last4Ssn: string): Promise<AxiosResponse<ResponseData<{ prefillInformation: PrefillInformation }>>> => {
    return await httpClient.post('/avenAdvisor/getPrefillInformation', { phoneNumber, last4Ssn })
}

export const getIsEligibleForPaidReferrals = async (): Promise<AxiosResponse<ResponseData<{ isEligibleForPaidReferrals: boolean }>>> => {
    return await httpClient.get('/avenAdvisor/getIsEligibleForPaidReferrals')
}

export const validateInviteCode = async (inviteCode: string): Promise<AxiosResponse<ResponseData<{ isValid: true } | { isValid: false; message: string }>>> => {
    return await httpClient.get('/avenAdvisor/validateInviteCode', { params: { inviteCode } })
}

export const copyReferrerPromos = async (inviteCode: string, refereePhoneNumber: string): Promise<AxiosResponse<ResponseData<{ copiedPromosCount: number }>>> => {
    return await httpClient.get('/avenAdvisor/copyReferrerPromos', { params: { inviteCode, refereePhoneNumber } })
}

export const assignPromosFromDMInviteCode = async (inviteCode: string, refereePhoneNumber: string): Promise<AxiosResponse<ResponseData<{ assignedPromoCodes: number }>>> => {
    return await httpClient.get('/avenAdvisor/assignPromosFromDMInviteCode', { params: { inviteCode, refereePhoneNumber } })
}

export const checkHasGottenRefereeBonus = async (phoneNumber: string): Promise<AxiosResponse<ResponseData<{ hasGottenRefereeBonus: boolean }>>> => {
    return await httpClient.get('/avenAdvisor/checkHasGottenRefereeBonus', { params: { phoneNumber } })
}

export const saveAvenFridaysSweepstakesRecord = async (payload: { entryButtonClicked?: boolean; tweetButtonClicked?: boolean }): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/saveAvenFridaysSweepstakesRecord', payload)
}

export const submitCuSurvey = async (payload: {
    currentFinancialInstitutions: string[]
    initialHavenAccountTransferAmount: string
    monthlyHavenAccountTransferAmount: string
    loanServicesInterest: string[]
    requiredServices: string[]
    servicesToSwitchToHaven: string[]
    importantAspectOfLoan: string[]
    wouldSignUpForHaven: boolean
}): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/submitCuSurvey', payload)
}

export const updateSsnDobForEmployee = async (ssn: string, dateOfBirth: Date): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/updateSsnDobForEmployee', { ssn, dateOfBirth })
}

export const createVisitAndUpdateStreak = async (): Promise<AxiosResponse<ResponseData<StreakResponse | null>>> => {
    return await httpClient.post('/avenAdvisor/createVisitAndUpdateStreak', {})
}

export interface PayoutHistoryItem {
    amount: number
    type: string
    status: string
    moreInformationText?: string
}
export const getPayoutHistory = async (): Promise<AxiosResponse<ResponseData<{ payoutHistory: PayoutHistoryItem[] }>>> => {
    return await httpClient.get('/avenAdvisor/getPayoutHistory')
}

let getOwnwellSavingsEstimatePromise: Promise<AxiosResponse<ResponseData<{ savingsEstimate: number } | null>>> | null = null

export const getOwnwellSavingsEstimate = async (): Promise<AxiosResponse<ResponseData<{ savingsEstimate: number } | null>>> => {
    if (!getOwnwellSavingsEstimatePromise) {
        logger.info('Fetching ownwell savings estimate')
        getOwnwellSavingsEstimatePromise = httpClient.get('/avenAdvisor/getOwnwellSavingsEstimate')
        getOwnwellSavingsEstimatePromise.finally(() => {
            getOwnwellSavingsEstimatePromise = null
        })
    }
    logger.info('Returning ownwell savings estimate promise')
    return await getOwnwellSavingsEstimatePromise
}

// Keep in sync with aven_backend/src/avenAdvisor/controller/avenAdvisorPlaidController.ts
interface AvenAdvisorInvestmentHoldingsItem {
    securityPriceDollars: number
    securityValueDollars: number
    costBasisDollars: number | null // The total cost basis of the holding (e.g., the total amount spent to acquire all assets currently in the holding). This is null if the cost basis is not available from plaid.
    securityQuantity: number
    securityName: string
    securityTicker: string
    securityType: string
    securityHistory: { date: Date; value: number; securityPriceDollars: number }[]
}

export interface AvenAdvisorInvestmentHoldingsResponse {
    holdingsData: {
        holdings: AvenAdvisorInvestmentHoldingsItem[]
        updatedAt: Date
        requiredPlaidActionPayload: { requiredPlaidAction: RequiredPlaidAction; requiredPlaidActionInstitutionId: string } | null
        totalHoldingsValue: number
        totalHoldingsValueHistory: { date: Date; value: number }[]
    } | null
}

let investmentHoldingPromise: Promise<AxiosResponse<ResponseData<AvenAdvisorInvestmentHoldingsResponse>>> | null = null

export const getInvestmentHoldings = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorInvestmentHoldingsResponse>>> => {
    if (!investmentHoldingPromise) {
        logger.info('Fetching investment holdings')
        investmentHoldingPromise = httpClient.get('/avenAdvisor/getInvestmentHoldings')
        investmentHoldingPromise.finally(() => {
            investmentHoldingPromise = null
        })
    }
    logger.info('Returning investment holdings promise')
    return await investmentHoldingPromise
}

// Keep in sync with aven_backend/src/avenAdvisor/controller/avenAdvisorController.ts
export enum PersonaStatus {
    WAITING_TO_BE_COMPLETED = 'WAITING_TO_BE_COMPLETED',
    NOT_NEEDED = 'NOT_NEEDED',
}

export const getPersonaStatus = async (): Promise<AxiosResponse<ResponseData<{ personaStatus: PersonaStatus }>>> => {
    return await httpClient.get('/avenAdvisor/checkAccountPersonaStatus')
}

export const getPersonaLink = async (): Promise<AxiosResponse<ResponseData<{ getPersonaLink: string }>>> => {
    return await httpClient.get('/avenAdvisor/getPersonaLink')
}

export enum BenzingaCongressionalChamber {
    HOUSE = 'House',
    SENATE = 'Senate',
}

// Keep in sync with aven_backend/src/avenAdvisor/manager/avenAdvisorBenzingaManager.ts
export interface AvenAdvisorNotablePersonTrades {
    name: string
    headshotUrl: string
    description: string
    trades: {
        transactionAmount: number
        isInsiderTrade: boolean
        securityName: string
        securityTicker: string
        transactionDate: Date
        didBuy: boolean
        securityLogoUrl: string | null
    }[]
}

let notableTradesPromise: Promise<AxiosResponse<ResponseData<{ notableTrades: AvenAdvisorNotablePersonTrades[] }>>> | null = null

export const getNotableTrades = async (): Promise<AxiosResponse<ResponseData<{ notableTrades: AvenAdvisorNotablePersonTrades[] }>>> => {
    if (!notableTradesPromise) {
        logger.info('Fetching notable trades')
        notableTradesPromise = httpClient.get('/avenAdvisor/getNotableTrades')
        notableTradesPromise.finally(() => {
            notableTradesPromise = null
        })
    }
    logger.info('Returning notable trades promise')
    return await notableTradesPromise
}

let getCrimeReportPromise: Promise<AxiosResponse<ResponseData<{ crimeReport: NeighborhoodCrimeData } | null>> | null> | null = null

export const getCrimeReport = async (forceRefresh: boolean, limit: number): Promise<AxiosResponse<ResponseData<{ crimeReport: NeighborhoodCrimeData } | null>> | null> => {
    if (!getCrimeReportPromise) {
        logger.info('Fetching crime report')
        getCrimeReportPromise = httpClient.get('/avenAdvisor/getCrimeReport', { params: { forceRefresh, limit } })
        getCrimeReportPromise.finally(() => {
            getCrimeReportPromise = null
        })
    }
    logger.info('Returning crime report promise')
    return await getCrimeReportPromise
}

let getLienInfoPromise: Promise<AxiosResponse<ResponseData<{ lienInfo: LienInfo } | null>>> | null = null

export const getLienInfo = async (): Promise<AxiosResponse<ResponseData<{ lienInfo: LienInfo } | null>>> => {
    if (!getLienInfoPromise) {
        logger.info('Fetching lien info')
        getLienInfoPromise = httpClient.get('/avenAdvisor/getLienInfo')
        getLienInfoPromise.finally(() => {
            getLienInfoPromise = null
        })
    }
    logger.info('Returning lien info promise')
    return await getLienInfoPromise
}

let getHomeEquityPromise: Promise<AxiosResponse<ResponseData<{ homeEquityInfo: HomeEquityInfo } | null>>> | null = null

export const getHomeEquityInfo = async (): Promise<AxiosResponse<ResponseData<{ homeEquityInfo: HomeEquityInfo } | null>>> => {
    if (!getHomeEquityPromise) {
        logger.info('Fetching home equity info')
        getHomeEquityPromise = httpClient.get('/avenAdvisor/getHomeEquityInfo')
        getHomeEquityPromise.finally(() => {
            getHomeEquityPromise = null
        })
    }
    logger.info('Returning home equity info promise')
    return await getHomeEquityPromise
}

export const getExpiredPlaidItems = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorExpiredPlaidItemsResponse>>> => {
    return await httpClient.get('/avenAdvisor/getExpiredPlaidItems')
}

let getCraigslistListingsPromise: Promise<AxiosResponse<ResponseData<{ allListings: CraigslistListingItem[] }>>> | null = null
export const getCraigslistListings = async (): Promise<AxiosResponse<ResponseData<{ allListings: CraigslistListingItem[] }>>> => {
    if (!getCraigslistListingsPromise) {
        logger.info('Fetching craigslist listings')
        getCraigslistListingsPromise = httpClient.get('/avenAdvisor/getCraigslistListings')
        getCraigslistListingsPromise.finally(() => {
            getCraigslistListingsPromise = null
        })
    }
    logger.info('Returning craigslist listings promise')
    return await getCraigslistListingsPromise
}

let getPushNotificationPromise: Promise<AxiosResponse<ResponseData<{ pushNotifications: AvenAdvisorPushNotificationCenterItem[] }>>> | null = null
export const getPushNotifications = async (): Promise<AxiosResponse<ResponseData<{ pushNotifications: AvenAdvisorPushNotificationCenterItem[] }>>> => {
    if (!getPushNotificationPromise) {
        logger.info('Fetching push notifications')
        getPushNotificationPromise = httpClient.get('/avenAdvisor/getPushNotifications')
        getPushNotificationPromise.finally(() => {
            getPushNotificationPromise = null
        })
    }
    logger.info('Returning push notifications promise')
    return await getPushNotificationPromise
}

export const logPushNotificationClick = async (pushNotificationId: number): Promise<AxiosResponse<ResponseData<null>>> => {
    return await httpClient.post('/avenAdvisor/logPushNotificationClick', { pushNotificationId })
}

export const getLatestLienReport = async (forceNewFetch: boolean = false): Promise<FetchAvenAdvisorLienReportResponse | null> => {
    const response = await httpClient.get('/avenAdvisor/getLatestLienReportDataForAddress', { params: { forceNewFetch: forceNewFetch ? 'true' : 'false' } })

    if (!response.data || !response.data.success || !response.data.payload) {
        return null
    }

    return {
        ...response.data.payload,
        updatedAt: response.data.payload.updatedAt ? new Date(response.data.payload.updatedAt) : undefined,
    }
}

export const getAvenAdvisorCreditCardRecommendations = async (): Promise<AvenAdvisorCreditCardRecommendationData[]> => {
    const results = (await httpClient.get('/avenAdvisor/getAllRecommendedCreditCards')) as AxiosResponse<ResponseData<AvenAdvisorCreditCardRecommendationData[]>>
    return results.data.payload
}

export const postGoogleOfflineConversion = async (phoneNumber: string, googleConversion: GoogleConversionName): Promise<AxiosResponse<ResponseData<null>> | null> => {
    return await httpClient.post('/avenAdvisor/googleOfflineConversion', {
        phoneNumber,
        googleConversion,
    })
}

export const postFacebookOfflineConversion = async (phoneNumber: string, facebookPixelStep: FacebookPixelStep): Promise<AxiosResponse<ResponseData<null>> | null> => {
    return await httpClient.post('/avenAdvisor/facebookOfflineConversion', {
        phoneNumber,
        facebookPixelStep,
    })
}

export const maybeInitializeMethodFi = async (): Promise<AxiosResponse<ResponseData<null>> | null> => {
    return await httpClient.post('/avenAdvisor/maybeInitializeMethodFi')
}

export const pollForSweepstakesV2PublicWinners = async (): Promise<PublicSweepstakesV2Result> => {
    const response = await httpClient.get('/avenAdvisor/pollForPublicSweepstakesV2Winner')
    return response.data.payload
}

export const employeeRefreshMethodFiEntity = async (): Promise<AxiosResponse<ResponseData<null>> | null> => {
    return await httpClient.post('/avenAdvisor/employeeRefreshMethodFiEntity')
}

export const getIsPlaidAuthenticatedForSweepstakes = async (): Promise<AxiosResponse<ResponseData<{ isPlaidAuthenticated: boolean }>> | null> => {
    return await httpClient.get('/avenAdvisor/getIsPlaidAuthenticatedForSweepstakes')
}

export const optOutSweepstakesV2 = async (): Promise<AxiosResponse<ResponseData<null>> | null> => {
    return await httpClient.post('/avenAdvisor/optOutOfSweepstakesV2')
}

export const convertWebOG = async (): Promise<AxiosResponse<ResponseData<null>> | null> => {
    return await httpClient.post('/avenAdvisor/convertWebOG')
}

export const searchAmazonProducts = async (request: AmazonAffiliateSearchRequest): Promise<AxiosResponse<ResponseData<AmazonAffiliateSearchResult>> | null> => {
    return await httpClient.post('/avenAdvisor/searchAmazonProducts', request)
}

export const recordSaleIntent = async (request: AmazonAffiliateLinkRequest): Promise<AxiosResponse<ResponseData<{ redirectUrl: string }>> | null> => {
    return await httpClient.post('/avenAdvisor/recordAmazonSaleIntent', request)
}

export const getBorrowingCapacity = async (): Promise<AxiosResponse<ResponseData<AvenAdvisorBorrowingCapacityData>>> => {
    return await httpClient.get('/avenAdvisor/getBorrowingCapacity')
}

// Keep in sync with aven_backend/src/avenAdvisor/controller/avenAdvisorPlaidController.ts
export interface AvenAdvisorNetWorthPercentileResponse {
    allUsers: number | null
    sameZipCode: number | null
    sameState: number | null
    sameAge: number | null
}

export const getFreeCoffeeInfo = async (): Promise<AxiosResponse<ResponseData<FreeCoffeeInfoResponse>> | null> => {
    return await httpClient.get('/avenAdvisor/getFreeCoffeeInfo')
}

export const redeemFreeCoffee = async (): Promise<AxiosResponse<ResponseData<RedeemFreeCoffeeResponse>> | null> => {
    return await httpClient.post('/avenAdvisor/redeemFreeCoffee')
}

export const getAffiliateRewardsLedger = async (startDate: Date, endDate: Date): Promise<AxiosResponse<ResponseData<{ ledger: AvenAffiliateRewardsPointsLedgerItem[] } | null>>> => {
    return await httpClient.get('/avenAdvisor/getAffiliateRewardsPointsLedger', { params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() } })
}

export const getSaleInfoFromAffiliateSaleId = async (affiliateSaleId: number): Promise<AxiosResponse<ResponseData<AvenAffiliateSaleInfo | null>>> => {
    return await httpClient.get('/avenAdvisor/getSaleInfoFromAffiliateSaleId', { params: { affiliateSaleId } })
}

export const redeemPointsForAdvisorLead = async (numPoints: number): Promise<AxiosResponse<ResponseData<{ signOnBonus: any } | null>>> => {
    return await httpClient.post('/avenAdvisor/redeemPointsForAdvisorLead', { numPoints })
}

export const refreshVehicles = async (): Promise<AxiosResponse<ResponseData<{ vehicle: VehicleInformation } | null>>> => {
    return await httpClient.post('/avenAdvisor/refreshVehicles')
}

export {
    getSessionInfo,
    requestTwoFactor,
    RequestTwoFaErrors,
    AuthFlow,
    routeToAuthFlow,
    checkTwoFa,
    checkTwoFaForPhoneNumberChange,
    createAccount,
    CreateAccountErrors,
    MARITAL_STATUS_TO_TEXT,
    HOME_OWNERSHIP_STATUS_TO_TEXT,
    signIn,
    resetPassword,
    ResetPasswordErrors,
    getTotalDebt,
    getInviteCode,
    plaidReportFetchState,
    startPlaidReportFetch,
    updateSocialSecurity,
    GetPayoutsAccountSetUpLinkErrors,
    getPayoutsAccountSetUpLink,
    initiateCreditOffer,
    pollForCreditOffers,
    getIllustrativeSavingsExample,
}
