"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanType = void 0;
/* @TranslateToPython @TranslateToEnum */
var PlanType;
(function (PlanType) {
    PlanType["BalanceTransfer"] = "Balance Transfer";
    PlanType["Purchase"] = "Purchase";
    PlanType["PromotionalPurchases"] = "Promotional Purchases";
    PlanType["Retail"] = "Retail";
    PlanType["Cash"] = "Cash";
})(PlanType = exports.PlanType || (exports.PlanType = {}));
