"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxDeductibleFloodCalculationType = exports.FloodInsuranceStatus = void 0;
var FloodInsuranceStatus;
(function (FloodInsuranceStatus) {
    FloodInsuranceStatus["notApplicable"] = "notApplicable";
    FloodInsuranceStatus["waitingDocuments"] = "waitingDocuments";
    FloodInsuranceStatus["reviewFailWaitingDocumentResubmission"] = "reviewFailWaitingDocumentResubmission";
    FloodInsuranceStatus["expiredDocumentsWaitingResubmission"] = "expiredDocumentsWaitingResubmission";
    FloodInsuranceStatus["documentPendingReview"] = "documentPendingReview";
    FloodInsuranceStatus["documentReviewSuccess"] = "documentReviewSuccess";
    FloodInsuranceStatus["lifeOfLoanWaitingDocuments"] = "lifeOfLoanWaitingDocuments";
})(FloodInsuranceStatus = exports.FloodInsuranceStatus || (exports.FloodInsuranceStatus = {}));
/**  @TranslateToPython @TranslateToEnum  */
var MaxDeductibleFloodCalculationType;
(function (MaxDeductibleFloodCalculationType) {
    MaxDeductibleFloodCalculationType["default"] = "default";
    MaxDeductibleFloodCalculationType["minOfDefaultAndPercentOfRequiredCoverage"] = "minOfDefaultAndPercentOfRequiredCoverage";
})(MaxDeductibleFloodCalculationType = exports.MaxDeductibleFloodCalculationType || (exports.MaxDeductibleFloodCalculationType = {}));
