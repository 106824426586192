"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankruptcyChapter = exports.INACTIVE_BANKRUPTCY_CASE_STATUSES = exports.BankruptcyStatus = void 0;
var BankruptcyStatus;
(function (BankruptcyStatus) {
    BankruptcyStatus["Filed"] = "Filed";
    BankruptcyStatus["Dismissed"] = "Dismissed";
    BankruptcyStatus["Discharged"] = "Discharged";
    BankruptcyStatus["InvalidRecord"] = "InvalidRecord";
})(BankruptcyStatus = exports.BankruptcyStatus || (exports.BankruptcyStatus = {}));
exports.INACTIVE_BANKRUPTCY_CASE_STATUSES = [BankruptcyStatus.Dismissed, BankruptcyStatus.Discharged, BankruptcyStatus.InvalidRecord];
var BankruptcyChapter;
(function (BankruptcyChapter) {
    BankruptcyChapter["CHAPTER_7_LIQUIDATION"] = "CHAPTER_7_LIQUIDATION";
    BankruptcyChapter["CHAPTER_11_REORGANIZATION"] = "CHAPTER_11_REORGANIZATION";
    BankruptcyChapter["CHAPTER_12_ADJUSTMENT_OF_DEBTS_OF_A_FAMILY_FARMER_OR_FISHERMAN_WITH_REGULAR_ANNUAL_INCOME"] = "CHAPTER_12_ADJUSTMENT_OF_DEBTS_OF_A_FAMILY_FARMER_OR_FISHERMAN_WITH_REGULAR_ANNUAL_INCOME";
    BankruptcyChapter["CHAPTER_13_ADJUSTMENT_OF_DEBTS_OF_AN_INDIVIDUAL_WITH_REGULAR_INCOME"] = "CHAPTER_13_ADJUSTMENT_OF_DEBTS_OF_AN_INDIVIDUAL_WITH_REGULAR_INCOME";
    BankruptcyChapter["CHAPTER_15_ANCILLARY_AND_OTHER_CROSS_BORDER_CASES"] = "CHAPTER_15_ANCILLARY_AND_OTHER_CROSS_BORDER_CASES";
})(BankruptcyChapter = exports.BankruptcyChapter || (exports.BankruptcyChapter = {}));
