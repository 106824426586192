"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERSONA_SUCCESS_STATUSES = exports.PERSONA_TERMINAL_STATUSES = exports.PERSONA_NOT_ATTEMPTED_STATUSES = exports.PersonaInquiryStatus = void 0;
/**
 * Persona inquiry statuses
 * See: https://docs.withpersona.com/docs/models-lifecycle#inquiry-statuses
 */
var PersonaInquiryStatus;
(function (PersonaInquiryStatus) {
    PersonaInquiryStatus["completed"] = "completed";
    PersonaInquiryStatus["failed"] = "failed";
    PersonaInquiryStatus["needs_review"] = "needs_review";
    PersonaInquiryStatus["created"] = "created";
    PersonaInquiryStatus["pending"] = "pending";
    PersonaInquiryStatus["expired"] = "expired";
    PersonaInquiryStatus["approved"] = "approved";
    PersonaInquiryStatus["declined"] = "declined";
})(PersonaInquiryStatus = exports.PersonaInquiryStatus || (exports.PersonaInquiryStatus = {}));
exports.PERSONA_NOT_ATTEMPTED_STATUSES = [PersonaInquiryStatus.created, PersonaInquiryStatus.pending, PersonaInquiryStatus.expired];
exports.PERSONA_TERMINAL_STATUSES = [
    PersonaInquiryStatus.needs_review,
    PersonaInquiryStatus.failed,
    PersonaInquiryStatus.completed,
    PersonaInquiryStatus.needs_review,
    PersonaInquiryStatus.approved,
    PersonaInquiryStatus.declined,
];
exports.PERSONA_SUCCESS_STATUSES = [PersonaInquiryStatus.completed, PersonaInquiryStatus.approved];
