"use strict";
/*
 * ULAD enums defined here:
 * https://docs.google.com/spreadsheets/d/13ppjfs8hs_SOgLMCHfwMcDRbL7CDtRCpv_3QR4xjLaU/edit?gid=770637105#gid=770637105
 *
 * Commented values are not used but defined in the ULAD spec
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mismo34CitizenshipResidencyType = exports.Mismo34ContactPointRoleType = exports.Mismo34MaritalStatusType = exports.Mismo34TaxpayerIdentifierType = exports.Mismo34PartyRoleType = exports.Mismo34HmdaApplicationTakenType = exports.Mismo34HmdaRaceDesignationType = exports.Mismo34HmdaRaceType = exports.Mismo34HmdaGenderType = exports.Mismo34HmdaEthnicityOriginType = exports.Mismo34HmdaEthnicityType = exports.Mismo34BankruptcyType = exports.Mismo34DeclarationPriorPropertyTitleType = exports.Mismo34DeclarationYesNoUnknown = exports.Mismo34RealEstateLiabilityType = exports.Mismo34NonRealEstateLiabilityType = exports.Mismo34ExpenseType = exports.Mismo34GiftOrGrantFundsSourceType = exports.Mismo34GiftOrGrantAssetType = exports.Mismo34OtherOtherAssetType = exports.Mismo34OtherAssetType = exports.Mismo34FinancialAccountAssetType = exports.Mismo34PropertyIntendedUsageType = exports.Mismo34PropertyCurrentUsageType = exports.Mismo34OwnedPropertyDispositionStatusType = exports.Mismo34OtherIncomeType = exports.Mismo34EmploymentIncomeType = exports.Mismo34EmploymentOwnershipInterestType = exports.Mismo34EmploymentStatusType = exports.Mismo34BorrowerResidencyBasisType = exports.Mismo34BorrowerResidencyType = exports.Mismo34AmortizationType = exports.Mismo34LoanAmortizationPeriodType = exports.Mismo34LienPriorityType = exports.Mismo34MortgageType = exports.Mismo34LoanPurposeType = exports.Mismo34RefinancePrimaryPurposeType = exports.Mismo34RefinanceCashOutDeterminationType = exports.Mismo34CurrentFirstMortgageHolderType = exports.Mismo34LoanRoleType = void 0;
/* ========== End of Common ========== */
/* ========== Start of Loan ========== */
// An attribute of LOAN used to identify the role the associated LOAN plays in the transaction.
var Mismo34LoanRoleType;
(function (Mismo34LoanRoleType) {
    Mismo34LoanRoleType["SubjectLoan"] = "SubjectLoan";
    // RelatedLoan = 'RelatedLoan',
})(Mismo34LoanRoleType = exports.Mismo34LoanRoleType || (exports.Mismo34LoanRoleType = {}));
// Specifies the investor holding the first mortgage that is being refinanced.
var Mismo34CurrentFirstMortgageHolderType;
(function (Mismo34CurrentFirstMortgageHolderType) {
    // Fannie Mae
    Mismo34CurrentFirstMortgageHolderType["FNM"] = "FNM";
})(Mismo34CurrentFirstMortgageHolderType = exports.Mismo34CurrentFirstMortgageHolderType || (exports.Mismo34CurrentFirstMortgageHolderType = {}));
// A value from a MISMO prescribed list that classifies a refinanced loan.
var Mismo34RefinanceCashOutDeterminationType;
(function (Mismo34RefinanceCashOutDeterminationType) {
    Mismo34RefinanceCashOutDeterminationType["CashOut"] = "CashOut";
    Mismo34RefinanceCashOutDeterminationType["LimitedCashOut"] = "LimitedCashOut";
    Mismo34RefinanceCashOutDeterminationType["NoCashOut"] = "NoCashOut";
})(Mismo34RefinanceCashOutDeterminationType = exports.Mismo34RefinanceCashOutDeterminationType || (exports.Mismo34RefinanceCashOutDeterminationType = {}));
// Specifies the primary purpose of the refinance.
var Mismo34RefinancePrimaryPurposeType;
(function (Mismo34RefinancePrimaryPurposeType) {
    Mismo34RefinancePrimaryPurposeType["DebtConsolidation"] = "DebtConsolidation";
    Mismo34RefinancePrimaryPurposeType["HomeImprovement"] = "HomeImprovement";
    Mismo34RefinancePrimaryPurposeType["Other"] = "Other";
})(Mismo34RefinancePrimaryPurposeType = exports.Mismo34RefinancePrimaryPurposeType || (exports.Mismo34RefinancePrimaryPurposeType = {}));
// Specifies the purpose for which the loan proceeds will be used.
var Mismo34LoanPurposeType;
(function (Mismo34LoanPurposeType) {
    Mismo34LoanPurposeType["Purchase"] = "Purchase";
    Mismo34LoanPurposeType["Refinance"] = "Refinance";
})(Mismo34LoanPurposeType = exports.Mismo34LoanPurposeType || (exports.Mismo34LoanPurposeType = {}));
// A value from a MISMO prescribed list that specifies the private or public sector entity under whose guidelines the mortgage is originated.
var Mismo34MortgageType;
(function (Mismo34MortgageType) {
    Mismo34MortgageType["Conventional"] = "Conventional";
    // FHA = 'FHA',
    // USDARuralDevelopment = 'USDARuralDevelopment',
    // VA = 'VA',
})(Mismo34MortgageType = exports.Mismo34MortgageType || (exports.Mismo34MortgageType = {}));
// A value from a MISMO prescribed list that specifies the priority of the lien against the subject property.
var Mismo34LienPriorityType;
(function (Mismo34LienPriorityType) {
    Mismo34LienPriorityType["FirstLien"] = "FirstLien";
    // SecondLien = 'SecondLien',
    // Other = 'Other',
})(Mismo34LienPriorityType = exports.Mismo34LienPriorityType || (exports.Mismo34LienPriorityType = {}));
// The duration of time used to define the period over which the loan is amortized.
var Mismo34LoanAmortizationPeriodType;
(function (Mismo34LoanAmortizationPeriodType) {
    Mismo34LoanAmortizationPeriodType["Month"] = "Month";
})(Mismo34LoanAmortizationPeriodType = exports.Mismo34LoanAmortizationPeriodType || (exports.Mismo34LoanAmortizationPeriodType = {}));
// A classification or description of a loan or a group of loans generally based on the changeability of the rate or payment over time.
// For subject mortgage loan, cannot be "PotentialNegativeAmortization" nor "ScheduledNegativeAmortization"
var Mismo34AmortizationType;
(function (Mismo34AmortizationType) {
    // GEM = 'GEM',
    // GPM = 'GPM',
    // AdjustableRate = 'AdjustableRate',
    Mismo34AmortizationType["Fixed"] = "Fixed";
    // PotentialNegativeAmortization = 'PotentialNegativeAmortization',
    // ScheduledNegativeAmortization = 'ScheduledNegativeAmortization',
    // Other = 'Other',
})(Mismo34AmortizationType = exports.Mismo34AmortizationType || (exports.Mismo34AmortizationType = {}));
/* ========== End of Loan ========== */
/* ========== Start of Address History ========== */
var Mismo34BorrowerResidencyType;
(function (Mismo34BorrowerResidencyType) {
    Mismo34BorrowerResidencyType["Current"] = "Current";
    Mismo34BorrowerResidencyType["Prior"] = "Prior";
})(Mismo34BorrowerResidencyType = exports.Mismo34BorrowerResidencyType || (exports.Mismo34BorrowerResidencyType = {}));
var Mismo34BorrowerResidencyBasisType;
(function (Mismo34BorrowerResidencyBasisType) {
    Mismo34BorrowerResidencyBasisType["LivingRentFree"] = "LivingRentFree";
    Mismo34BorrowerResidencyBasisType["Own"] = "Own";
    Mismo34BorrowerResidencyBasisType["Rent"] = "Rent";
})(Mismo34BorrowerResidencyBasisType = exports.Mismo34BorrowerResidencyBasisType || (exports.Mismo34BorrowerResidencyBasisType = {}));
/* ========== End of Address History ========== */
/* ========== Start of Employment History ========== */
var Mismo34EmploymentStatusType;
(function (Mismo34EmploymentStatusType) {
    Mismo34EmploymentStatusType["Current"] = "Current";
    Mismo34EmploymentStatusType["Previous"] = "Previous";
})(Mismo34EmploymentStatusType = exports.Mismo34EmploymentStatusType || (exports.Mismo34EmploymentStatusType = {}));
var Mismo34EmploymentOwnershipInterestType;
(function (Mismo34EmploymentOwnershipInterestType) {
    Mismo34EmploymentOwnershipInterestType["GreaterThanOrEqualTo25Percent"] = "GreaterThanOrEqualTo25Percent";
    Mismo34EmploymentOwnershipInterestType["LessThan25Percent"] = "LessThan25Percent";
})(Mismo34EmploymentOwnershipInterestType = exports.Mismo34EmploymentOwnershipInterestType || (exports.Mismo34EmploymentOwnershipInterestType = {}));
/* ========== End of Employment History ========== */
/* ========== Start of Income Item ========== */
var Mismo34EmploymentIncomeType;
(function (Mismo34EmploymentIncomeType) {
    /* Employment income */
    Mismo34EmploymentIncomeType["Base"] = "Base";
    Mismo34EmploymentIncomeType["Bonus"] = "Bonus";
    Mismo34EmploymentIncomeType["Commissions"] = "Commissions";
    Mismo34EmploymentIncomeType["ContractBasis"] = "ContractBasis";
    Mismo34EmploymentIncomeType["MilitaryBasePay"] = "MilitaryBasePay";
    Mismo34EmploymentIncomeType["MilitaryClothesAllowance"] = "MilitaryClothesAllowance";
    Mismo34EmploymentIncomeType["MilitaryCombatPay"] = "MilitaryCombatPay";
    Mismo34EmploymentIncomeType["MilitaryFlightPay"] = "MilitaryFlightPay";
    Mismo34EmploymentIncomeType["MilitaryHazardPay"] = "MilitaryHazardPay";
    Mismo34EmploymentIncomeType["MilitaryOverseasPay"] = "MilitaryOverseasPay";
    Mismo34EmploymentIncomeType["MilitaryPropPay"] = "MilitaryPropPay";
    Mismo34EmploymentIncomeType["MilitaryQuartersAllowance"] = "MilitaryQuartersAllowance";
    Mismo34EmploymentIncomeType["MilitaryRationsAllowance"] = "MilitaryRationsAllowance";
    Mismo34EmploymentIncomeType["MilitaryVariableHousingAllowance"] = "MilitaryVariableHousingAllowance";
    Mismo34EmploymentIncomeType["Overtime"] = "Overtime";
    Mismo34EmploymentIncomeType["Other"] = "Other";
})(Mismo34EmploymentIncomeType = exports.Mismo34EmploymentIncomeType || (exports.Mismo34EmploymentIncomeType = {}));
var Mismo34OtherIncomeType;
(function (Mismo34OtherIncomeType) {
    /* Other sources of income */
    Mismo34OtherIncomeType["AccessoryUnitIncome"] = "AccessoryUnitIncome";
    Mismo34OtherIncomeType["Alimony"] = "Alimony";
    Mismo34OtherIncomeType["AutomobileAllowance"] = "AutomobileAllowance";
    Mismo34OtherIncomeType["BoarderIncome"] = "BoarderIncome";
    Mismo34OtherIncomeType["CapitalGains"] = "CapitalGains";
    Mismo34OtherIncomeType["ChildSupport"] = "ChildSupport";
    Mismo34OtherIncomeType["DefinedContributionPlan"] = "DefinedContributionPlan";
    Mismo34OtherIncomeType["Disability"] = "Disability";
    Mismo34OtherIncomeType["DividendsInterest"] = "DividendsInterest";
    Mismo34OtherIncomeType["EmploymentRelatedAccount"] = "EmploymentRelatedAccount";
    Mismo34OtherIncomeType["FosterCare"] = "FosterCare";
    Mismo34OtherIncomeType["HousingAllowance"] = "HousingAllowance";
    Mismo34OtherIncomeType["HousingChoiceVoucherProgram"] = "HousingChoiceVoucherProgram";
    Mismo34OtherIncomeType["MortgageCreditCertificate"] = "MortgageCreditCertificate";
    Mismo34OtherIncomeType["MortgageDifferential"] = "MortgageDifferential";
    Mismo34OtherIncomeType["NonBorrowerHouseholdIncome"] = "NonBorrowerHouseholdIncome";
    Mismo34OtherIncomeType["NotesReceivableInstallment"] = "NotesReceivableInstallment";
    Mismo34OtherIncomeType["Pension"] = "Pension";
    Mismo34OtherIncomeType["PublicAssistance"] = "PublicAssistance";
    Mismo34OtherIncomeType["Royalties"] = "Royalties";
    Mismo34OtherIncomeType["SeparateMaintenance"] = "SeparateMaintenance";
    Mismo34OtherIncomeType["SocialSecurity"] = "SocialSecurity";
    Mismo34OtherIncomeType["TemporaryLeave"] = "TemporaryLeave";
    Mismo34OtherIncomeType["TipIncome"] = "TipIncome";
    Mismo34OtherIncomeType["Trust"] = "Trust";
    Mismo34OtherIncomeType["Unemployment"] = "Unemployment";
    Mismo34OtherIncomeType["VABenefitsNonEducational"] = "VABenefitsNonEducational";
    Mismo34OtherIncomeType["Other"] = "Other";
})(Mismo34OtherIncomeType = exports.Mismo34OtherIncomeType || (exports.Mismo34OtherIncomeType = {}));
/* ========== End of Income Item ========== */
/* ========== Start of Owned Property ========== */
var Mismo34OwnedPropertyDispositionStatusType;
(function (Mismo34OwnedPropertyDispositionStatusType) {
    Mismo34OwnedPropertyDispositionStatusType["PendingSale"] = "PendingSale";
    Mismo34OwnedPropertyDispositionStatusType["Retain"] = "Retain";
    Mismo34OwnedPropertyDispositionStatusType["Sold"] = "Sold";
})(Mismo34OwnedPropertyDispositionStatusType = exports.Mismo34OwnedPropertyDispositionStatusType || (exports.Mismo34OwnedPropertyDispositionStatusType = {}));
var Mismo34PropertyCurrentUsageType;
(function (Mismo34PropertyCurrentUsageType) {
    Mismo34PropertyCurrentUsageType["Investment"] = "Investment";
    Mismo34PropertyCurrentUsageType["PrimaryResidence"] = "PrimaryResidence";
    Mismo34PropertyCurrentUsageType["SecondHome"] = "SecondHome";
})(Mismo34PropertyCurrentUsageType = exports.Mismo34PropertyCurrentUsageType || (exports.Mismo34PropertyCurrentUsageType = {}));
var Mismo34PropertyIntendedUsageType;
(function (Mismo34PropertyIntendedUsageType) {
    Mismo34PropertyIntendedUsageType["Investment"] = "Investment";
    Mismo34PropertyIntendedUsageType["PrimaryResidence"] = "PrimaryResidence";
    Mismo34PropertyIntendedUsageType["SecondHome"] = "SecondHome";
    Mismo34PropertyIntendedUsageType["Other"] = "Other";
})(Mismo34PropertyIntendedUsageType = exports.Mismo34PropertyIntendedUsageType || (exports.Mismo34PropertyIntendedUsageType = {}));
/* ========== End of Owned Property ========== */
/* ========== Start of Asset ========== */
var Mismo34FinancialAccountAssetType;
(function (Mismo34FinancialAccountAssetType) {
    Mismo34FinancialAccountAssetType["Bond"] = "Bond";
    Mismo34FinancialAccountAssetType["BridgeLoanNotDeposited"] = "BridgeLoanNotDeposited";
    Mismo34FinancialAccountAssetType["CertificateOfDepositTimeDeposit"] = "CertificateOfDepositTimeDeposit";
    Mismo34FinancialAccountAssetType["CheckingAccount"] = "CheckingAccount";
    Mismo34FinancialAccountAssetType["IndividualDevelopmentAccount"] = "IndividualDevelopmentAccount";
    Mismo34FinancialAccountAssetType["LifeInsurance"] = "LifeInsurance";
    Mismo34FinancialAccountAssetType["MoneyMarketFund"] = "MoneyMarketFund";
    Mismo34FinancialAccountAssetType["MutualFund"] = "MutualFund";
    Mismo34FinancialAccountAssetType["RetirementFund"] = "RetirementFund";
    Mismo34FinancialAccountAssetType["SavingsAccount"] = "SavingsAccount";
    Mismo34FinancialAccountAssetType["Stock"] = "Stock";
    Mismo34FinancialAccountAssetType["StockOptions"] = "StockOptions";
    Mismo34FinancialAccountAssetType["TrustAccount"] = "TrustAccount";
})(Mismo34FinancialAccountAssetType = exports.Mismo34FinancialAccountAssetType || (exports.Mismo34FinancialAccountAssetType = {}));
var Mismo34OtherAssetType;
(function (Mismo34OtherAssetType) {
    Mismo34OtherAssetType["CashOnHand"] = "CashOnHand";
    Mismo34OtherAssetType["PendingNetSaleProceedsFromRealEstateAssets"] = "PendingNetSaleProceedsFromRealEstateAssets";
    Mismo34OtherAssetType["ProceedsFromSaleOfNonRealEstateAsset"] = "ProceedsFromSaleOfNonRealEstateAsset";
    Mismo34OtherAssetType["ProceedsFromSecuredLoan"] = "ProceedsFromSecuredLoan";
    Mismo34OtherAssetType["ProceedsFromUnsecuredLoan"] = "ProceedsFromUnsecuredLoan";
    // Other - should only appear in final MISMO xml so it is not an enum value
})(Mismo34OtherAssetType = exports.Mismo34OtherAssetType || (exports.Mismo34OtherAssetType = {}));
/* "Other" types of "Other" assets - on MISMO, must fill AssetType with "Other" and use this enum value in "AssetTypeOtherDescription" */
var Mismo34OtherOtherAssetType;
(function (Mismo34OtherOtherAssetType) {
    Mismo34OtherOtherAssetType["OtherLiquidAsset"] = "OtherLiquidAsset";
    Mismo34OtherOtherAssetType["OtherNonLiquidAsset"] = "OtherNonLiquidAsset";
})(Mismo34OtherOtherAssetType = exports.Mismo34OtherOtherAssetType || (exports.Mismo34OtherOtherAssetType = {}));
var Mismo34GiftOrGrantAssetType;
(function (Mismo34GiftOrGrantAssetType) {
    Mismo34GiftOrGrantAssetType["GiftOfCash"] = "GiftOfCash";
    Mismo34GiftOrGrantAssetType["GiftOfPropertyEquity"] = "GiftOfPropertyEquity";
    Mismo34GiftOrGrantAssetType["Grant"] = "Grant";
})(Mismo34GiftOrGrantAssetType = exports.Mismo34GiftOrGrantAssetType || (exports.Mismo34GiftOrGrantAssetType = {}));
var Mismo34GiftOrGrantFundsSourceType;
(function (Mismo34GiftOrGrantFundsSourceType) {
    Mismo34GiftOrGrantFundsSourceType["CommunityNonProfit"] = "CommunityNonProfit";
    Mismo34GiftOrGrantFundsSourceType["Employer"] = "Employer";
    Mismo34GiftOrGrantFundsSourceType["FederalAgency"] = "FederalAgency";
    Mismo34GiftOrGrantFundsSourceType["Lender"] = "Lender";
    Mismo34GiftOrGrantFundsSourceType["LocalAgency"] = "LocalAgency";
    Mismo34GiftOrGrantFundsSourceType["Other"] = "Other";
    Mismo34GiftOrGrantFundsSourceType["Parent"] = "Parent";
    Mismo34GiftOrGrantFundsSourceType["Relative"] = "Relative";
    Mismo34GiftOrGrantFundsSourceType["ReligiousNonProfit"] = "ReligiousNonProfit";
    Mismo34GiftOrGrantFundsSourceType["StateAgency"] = "StateAgency";
    Mismo34GiftOrGrantFundsSourceType["UnmarriedPartner"] = "UnmarriedPartner";
    Mismo34GiftOrGrantFundsSourceType["UnrelatedFriend"] = "UnrelatedFriend";
})(Mismo34GiftOrGrantFundsSourceType = exports.Mismo34GiftOrGrantFundsSourceType || (exports.Mismo34GiftOrGrantFundsSourceType = {}));
/* ========== End of Collateral ========== */
/* ========== Start of Expense ========== */
var Mismo34ExpenseType;
(function (Mismo34ExpenseType) {
    Mismo34ExpenseType["Alimony"] = "Alimony";
    Mismo34ExpenseType["ChildSupport"] = "ChildSupport";
    Mismo34ExpenseType["JobRelatedExpenses"] = "JobRelatedExpenses";
    Mismo34ExpenseType["SeparateMaintenanceExpense"] = "SeparateMaintenanceExpense";
    Mismo34ExpenseType["OtherExpense"] = "OtherExpense";
})(Mismo34ExpenseType = exports.Mismo34ExpenseType || (exports.Mismo34ExpenseType = {}));
/* ========== End of Expense ========== */
/* ========== Start of Liability ========== */
var Mismo34NonRealEstateLiabilityType;
(function (Mismo34NonRealEstateLiabilityType) {
    Mismo34NonRealEstateLiabilityType["CollectionsJudgmentsAndLiens"] = "CollectionsJudgmentsAndLiens";
    Mismo34NonRealEstateLiabilityType["Installment"] = "Installment";
    Mismo34NonRealEstateLiabilityType["LeasePayment"] = "LeasePayment";
    Mismo34NonRealEstateLiabilityType["Open30DayChargeAccount"] = "Open30DayChargeAccount";
    Mismo34NonRealEstateLiabilityType["Revolving"] = "Revolving";
    Mismo34NonRealEstateLiabilityType["Taxes"] = "Taxes";
    Mismo34NonRealEstateLiabilityType["TaxLien"] = "TaxLien";
    Mismo34NonRealEstateLiabilityType["Other"] = "Other";
})(Mismo34NonRealEstateLiabilityType = exports.Mismo34NonRealEstateLiabilityType || (exports.Mismo34NonRealEstateLiabilityType = {}));
var Mismo34RealEstateLiabilityType;
(function (Mismo34RealEstateLiabilityType) {
    Mismo34RealEstateLiabilityType["HELOC"] = "HELOC";
    Mismo34RealEstateLiabilityType["MortgageLoan"] = "MortgageLoan";
})(Mismo34RealEstateLiabilityType = exports.Mismo34RealEstateLiabilityType || (exports.Mismo34RealEstateLiabilityType = {}));
/* ========== End of Liability ========== */
/* ========== Start of Declaration ========== */
var Mismo34DeclarationYesNoUnknown;
(function (Mismo34DeclarationYesNoUnknown) {
    Mismo34DeclarationYesNoUnknown["Yes"] = "Yes";
    Mismo34DeclarationYesNoUnknown["No"] = "No";
    Mismo34DeclarationYesNoUnknown["Unknown"] = "Unknown";
})(Mismo34DeclarationYesNoUnknown = exports.Mismo34DeclarationYesNoUnknown || (exports.Mismo34DeclarationYesNoUnknown = {}));
var Mismo34DeclarationPriorPropertyTitleType;
(function (Mismo34DeclarationPriorPropertyTitleType) {
    Mismo34DeclarationPriorPropertyTitleType["JointWithOtherThanSpouse"] = "JointWithOtherThanSpouse";
    Mismo34DeclarationPriorPropertyTitleType["JointWithSpouse"] = "JointWithSpouse";
    Mismo34DeclarationPriorPropertyTitleType["Sole"] = "Sole";
})(Mismo34DeclarationPriorPropertyTitleType = exports.Mismo34DeclarationPriorPropertyTitleType || (exports.Mismo34DeclarationPriorPropertyTitleType = {}));
var Mismo34BankruptcyType;
(function (Mismo34BankruptcyType) {
    Mismo34BankruptcyType["ChapterEleven"] = "ChapterEleven";
    Mismo34BankruptcyType["ChapterSeven"] = "ChapterSeven";
    Mismo34BankruptcyType["ChapterThirteen"] = "ChapterThirteen";
    Mismo34BankruptcyType["ChapterTwelve"] = "ChapterTwelve";
})(Mismo34BankruptcyType = exports.Mismo34BankruptcyType || (exports.Mismo34BankruptcyType = {}));
/* ========== End of Declaration ========== */
/* ========== Start of HMDA ========== */
var Mismo34HmdaEthnicityType;
(function (Mismo34HmdaEthnicityType) {
    Mismo34HmdaEthnicityType["HispanicOrLatino"] = "HispanicOrLatino";
    Mismo34HmdaEthnicityType["NotHispanicOrLatino"] = "NotHispanicOrLatino";
})(Mismo34HmdaEthnicityType = exports.Mismo34HmdaEthnicityType || (exports.Mismo34HmdaEthnicityType = {}));
var Mismo34HmdaEthnicityOriginType;
(function (Mismo34HmdaEthnicityOriginType) {
    Mismo34HmdaEthnicityOriginType["Cuban"] = "Cuban";
    Mismo34HmdaEthnicityOriginType["Mexican"] = "Mexican";
    Mismo34HmdaEthnicityOriginType["Other"] = "Other";
    Mismo34HmdaEthnicityOriginType["PuertoRican"] = "PuertoRican";
})(Mismo34HmdaEthnicityOriginType = exports.Mismo34HmdaEthnicityOriginType || (exports.Mismo34HmdaEthnicityOriginType = {}));
var Mismo34HmdaGenderType;
(function (Mismo34HmdaGenderType) {
    Mismo34HmdaGenderType["ApplicantSelectedBothMaleAndFemale"] = "ApplicantSelectedBothMaleAndFemale";
    Mismo34HmdaGenderType["Female"] = "Female";
    Mismo34HmdaGenderType["Male"] = "Male";
})(Mismo34HmdaGenderType = exports.Mismo34HmdaGenderType || (exports.Mismo34HmdaGenderType = {}));
var Mismo34HmdaRaceType;
(function (Mismo34HmdaRaceType) {
    Mismo34HmdaRaceType["AmericanIndianOrAlaskaNative"] = "AmericanIndianOrAlaskaNative";
    Mismo34HmdaRaceType["Asian"] = "Asian";
    Mismo34HmdaRaceType["BlackOrAfricanAmerican"] = "BlackOrAfricanAmerican";
    Mismo34HmdaRaceType["NativeHawaiianOrOtherPacificIslander"] = "NativeHawaiianOrOtherPacificIslander";
    Mismo34HmdaRaceType["White"] = "White";
})(Mismo34HmdaRaceType = exports.Mismo34HmdaRaceType || (exports.Mismo34HmdaRaceType = {}));
var Mismo34HmdaRaceDesignationType;
(function (Mismo34HmdaRaceDesignationType) {
    Mismo34HmdaRaceDesignationType["AsianIndian"] = "AsianIndian";
    Mismo34HmdaRaceDesignationType["Chinese"] = "Chinese";
    Mismo34HmdaRaceDesignationType["Filipino"] = "Filipino";
    Mismo34HmdaRaceDesignationType["GuamanianOrChamorro"] = "GuamanianOrChamorro";
    Mismo34HmdaRaceDesignationType["Japanese"] = "Japanese";
    Mismo34HmdaRaceDesignationType["Korean"] = "Korean";
    Mismo34HmdaRaceDesignationType["NativeHawaiian"] = "NativeHawaiian";
    Mismo34HmdaRaceDesignationType["OtherAsian"] = "OtherAsian";
    Mismo34HmdaRaceDesignationType["OtherPacificIslander"] = "OtherPacificIslander";
    Mismo34HmdaRaceDesignationType["Samoan"] = "Samoan";
    Mismo34HmdaRaceDesignationType["Vietnamese"] = "Vietnamese";
})(Mismo34HmdaRaceDesignationType = exports.Mismo34HmdaRaceDesignationType || (exports.Mismo34HmdaRaceDesignationType = {}));
var Mismo34HmdaApplicationTakenType;
(function (Mismo34HmdaApplicationTakenType) {
    // Email = "Email",
    // FaceToFace = "FaceToFace",
    // Fax = "Fax",
    Mismo34HmdaApplicationTakenType["Internet"] = "Internet";
    // Mail = "Mail",
    // Telephone = "Telephone",
})(Mismo34HmdaApplicationTakenType = exports.Mismo34HmdaApplicationTakenType || (exports.Mismo34HmdaApplicationTakenType = {}));
/* ========== End of HMDA ========== */
/* ========== Start of Party ========== */
var Mismo34PartyRoleType;
(function (Mismo34PartyRoleType) {
    // SubmittingParty = 'SubmittingParty',
    Mismo34PartyRoleType["Borrower"] = "Borrower";
    // NotePayTo = 'NotePayTo',
    Mismo34PartyRoleType["LoanOriginationCompany"] = "LoanOriginationCompany";
    Mismo34PartyRoleType["LoanOriginator"] = "LoanOriginator";
    Mismo34PartyRoleType["PropertyOwner"] = "PropertyOwner";
    // Trust = 'Trust',
    // HousingCounselingAgency = 'HousingCounselingAgency',
})(Mismo34PartyRoleType = exports.Mismo34PartyRoleType || (exports.Mismo34PartyRoleType = {}));
var Mismo34TaxpayerIdentifierType;
(function (Mismo34TaxpayerIdentifierType) {
    // IndividualTaxpayerIdentificationNumber = 'IndividualTaxpayerIdentificationNumber',
    Mismo34TaxpayerIdentifierType["SocialSecurityNumber"] = "SocialSecurityNumber";
    Mismo34TaxpayerIdentifierType["EmployerIdentificationNumber"] = "EmployerIdentificationNumber";
})(Mismo34TaxpayerIdentifierType = exports.Mismo34TaxpayerIdentifierType || (exports.Mismo34TaxpayerIdentifierType = {}));
var Mismo34MaritalStatusType;
(function (Mismo34MaritalStatusType) {
    Mismo34MaritalStatusType["Married"] = "Married";
    Mismo34MaritalStatusType["Separated"] = "Separated";
    Mismo34MaritalStatusType["Unmarried"] = "Unmarried";
})(Mismo34MaritalStatusType = exports.Mismo34MaritalStatusType || (exports.Mismo34MaritalStatusType = {}));
var Mismo34ContactPointRoleType;
(function (Mismo34ContactPointRoleType) {
    Mismo34ContactPointRoleType["Home"] = "Home";
    Mismo34ContactPointRoleType["Mobile"] = "Mobile";
    Mismo34ContactPointRoleType["Work"] = "Work";
})(Mismo34ContactPointRoleType = exports.Mismo34ContactPointRoleType || (exports.Mismo34ContactPointRoleType = {}));
var Mismo34CitizenshipResidencyType;
(function (Mismo34CitizenshipResidencyType) {
    Mismo34CitizenshipResidencyType["NonPermanentResidentAlien"] = "NonPermanentResidentAlien";
    Mismo34CitizenshipResidencyType["PermanentResidentAlien"] = "PermanentResidentAlien";
    Mismo34CitizenshipResidencyType["USCitizen"] = "USCitizen";
})(Mismo34CitizenshipResidencyType = exports.Mismo34CitizenshipResidencyType || (exports.Mismo34CitizenshipResidencyType = {}));
