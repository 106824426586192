<template>
    <div class="tw-h-full tw-flex tw-flex-col tw-overflow-y-scroll">
        <!-- LOADING INDICATOR -->
        <loading-indicator
            v-if="loading"
            class="vh-100 px-1"
            :title="loadingTitle"
        />

        <!-- MAIN CONTENT -->
        <div
            v-show="!loading"
            class="tw-p-4 tw-flex tw-flex-col tw-flex-grow"
            :style="containerStyle"
        >
            <div
                class="d-flex flex-row align-items-center"
                v-if="pageTitle || hasBack || hasSkip || hasDropDown"
            >
                <a
                    @click="goBack"
                    @keydown.enter="goBack"
                    class="d-block my-2"
                    v-show="hasBack"
                >
                    <img
                        src="@/assets/images/global/BackArrow.svg"
                        alt="Back"
                        v-show="hasBack"
                    >
                </a>
                <h5
                    v-if="!!pageTitle"
                    class="page-title fw-normal flex-fill text-center mb-0"
                >
                    {{ pageTitle }}
                </h5>
                <div
                    class="d-block my-3"
                    v-if="hasDropDown"
                >
                    <dropdown :menu-items="dropdownMenuItems">
                        <template #toggler>
                            <img
                                class="top-right-image"
                                src="@/assets/images/three-dots-icon.svg"
                                alt="Toggle Dropdown"
                            >
                        </template>
                    </dropdown>
                </div>
                <div
                    class="d-block mt-3 ms-auto"
                    v-if="!hasDropDown && hasSkip"
                    @click="skipAction"
                    @keydown.enter="skipAction"
                >
                    <h6
                        data-testid="header-skip-button"
                        class="fw-light tw-font-proximaNova"
                    >
                        Skip
                    </h6>
                </div>
            </div>
            <!-- ERROR MESSAGE -->
            <div
                v-if="errorText"
                class="alert-container"
                data-testid="onboarding-error-text"
            >
                <div
                    class="alert alert-warning text-center"
                    role="alert"
                >
                    <span v-html="errorText" />
                </div>
            </div>
            <div class="d-flex flex-column">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { logger } from '@/utils/logger'
    import Dropdown from 'aven_shared/src/components/Dropdown'
    import { getSafeAreaPaddingBottom, getSafeAreaPaddingTop } from '@/utils/safeAreaUtils'

    export default {
        name: 'OnboardingLayout',
        props: {
            pageTitle: { type: String, default: null, required: false },
            hasBack: { type: Boolean, default: true },
            backAction: { type: Function, default: null, required: false },
            loading: { type: Boolean },
            loadingTitle: { type: String, default: 'Loading...' },
            errorText: { type: String, default: '' },
            backgroundColor: { type: String, default: null, required: false },
            titleBackgroundColor: { type: String, default: null, required: false },
            hasDropDown: { type: Boolean, default: false },
            hasSkip: { type: Boolean, default: false },
            skipAction: { type: Function, default: null, required: false },
            dropdownMenuItems: {
                type: Array,
                default: () => [],
                required: false,
            },
        },
        components: {
            LoadingIndicator,
            Dropdown,
        },
        methods: {
            goBack: function () {
                if (this.backAction) {
                    logger.log(`OnboardingLayout has a back action, so executing that on back click`)
                    this.backAction()
                } else {
                    logger.log(`OnboardingLayout does not have a back action, so calling this.$router.back()`)
                    this.$router.back()
                }
            },
        },
        computed: {
            containerStyle: function () {
                let style = `padding-top: ${getSafeAreaPaddingTop()}px; padding-bottom: ${getSafeAreaPaddingBottom()}px;`
                if (this.backgroundColor) {
                    style += `background-color: ${this.backgroundColor};`
                }

                return style
            },
        },
    }
</script>

<style lang="scss" scoped>
    .page-title {
        // Counteracts the width of the back arrow, so the page title aligns properly.
        margin-right: 12px;
    }
    .top-right-image {
        height: 24px;
        aspect-ratio: 1/1;
    }
</style>
