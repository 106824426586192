import { toFormattedUSD } from '@/utils/format'
import { GetSweepstakesStatusResponse, HomeOwnershipStatus, SweepstakesStatus } from 'aven_types'
import store from '@/store'

export enum SweepstakesRenderType {
    DO_NOT_RENDER = 'DO_NOT_RENDER',
    OPEN = 'OPEN',
    CLOSED_DID_NOT_WIN = 'CLOSED_DID_NOT_WIN',
    CLOSED_WON_STRIPE_NOT_CONNECTED = 'CLOSED_WON_STRIPE_NOT_CONNECTED',
    CLOSED_WON_STRIPE_CONNECTED = 'CLOSED_WON_STRIPE_CONNECTED',
}
export const getSweepstakesRenderType = (sweepstakesStatus: GetSweepstakesStatusResponse | null): SweepstakesRenderType => {
    if (!sweepstakesStatus) {
        return SweepstakesRenderType.DO_NOT_RENDER
    }

    if (sweepstakesStatus.status === SweepstakesStatus.OPEN) {
        return SweepstakesRenderType.OPEN
    } else if (sweepstakesStatus.status === SweepstakesStatus.CLOSED) {
        if (sweepstakesStatus.didWin && sweepstakesStatus.isStripeConnected) {
            return SweepstakesRenderType.CLOSED_WON_STRIPE_CONNECTED
        }
        // won but stripe is not connected
        else if (sweepstakesStatus.didWin) {
            return SweepstakesRenderType.CLOSED_WON_STRIPE_NOT_CONNECTED
        } else {
            return SweepstakesRenderType.CLOSED_DID_NOT_WIN
        }
    }
    return SweepstakesRenderType.DO_NOT_RENDER
}

export const buildSweepstakesTwitterPrefilledText = (inviteCode: string, prizeAmount: number): string => {
    return `Loving @AvenAdvisor for tracking subscriptions, credit score, and even local home sales! 📊🏠 Plus, their weekly lottery could cover my purchases (up to ${toFormattedUSD(
        prizeAmount,
        0
    )})! 🤑
Money management with perks? Yes please! 🙌
Join me: ${inviteCode}
#AvenFridays
            `
}

export const SWEEPSTAKES_V2_TERMS_AND_CONDITIONS_LINK = 'https://advisor.aven.com/docs/WeeklySweepstakesV2Terms-12-18-24.pdf'

export const ADVISOR_MORTGAGE_SWEEPSTAKES_INVALID_STATES = ['NY', 'FL']

export const checkSweepstakesEligibility = (): boolean => {
    const contest = store.getters.getSweepstakesV2
    return (
        contest &&
        contest.status === SweepstakesStatus.OPEN &&
        store.state.homeOwnershipStatus === HomeOwnershipStatus.OWN &&
        Date.parse(contest.startDate) <= Date.now() &&
        !ADVISOR_MORTGAGE_SWEEPSTAKES_INVALID_STATES.includes(store.state.addressState)
    )
}
