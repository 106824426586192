"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDologyResult = void 0;
var IDologyResult;
(function (IDologyResult) {
    IDologyResult["pass"] = "pass";
    /**
     * Note: This status is not provided by IDology
     * There are cases where IDology's <summary-result/> response node tells us the API call failed, but examining
     * the response in more detail indicates it actually might have succeeded. One notable example: the SSN_COMPLETION
     * endpoint. Sometimes the IDology response includes a full-9 SSN but the summary result is a failure. This is
     * often accompanied by the resultcode.ssn.within.one.digit qualifier. If that's the case, we can allow the
     * applicant to move forward, but if there's a failure down the line (i.e. the EXPECT_ID api call) we can send
     * the applicant through the "type in you SSN" flow (RouteOption.ssnVerification).
     */
    IDologyResult["tentativePassFullSsn"] = "tentativePassFullSsn";
    IDologyResult["fail"] = "fail";
    IDologyResult["questions"] = "questions";
    IDologyResult["challenge"] = "challenge";
    /**
     * This is not provided by IDology, but we use it to indicate situations
     * where we allow users to move on despite their IDology fail
     */
    IDologyResult["allowableFail"] = "allowableFail";
    IDologyResult["ssnMismatch"] = "ssnMismatch";
    IDologyResult["error"] = "error";
})(IDologyResult = exports.IDologyResult || (exports.IDologyResult = {}));
