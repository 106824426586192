"use strict";
/* --- Amazon Affiliate API Request/Response Types --- */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmazonAffiliateRequestMethod = exports.AmazonAffiliateRequestType = exports.AmazonAffiliateSearchItemsResponseErrorCode = exports.AmazonSearchIndex = exports.AmazonAffiliateBrowseNodeId = exports.AmazonAffiliateSearchItemsSortBy = void 0;
var AmazonAffiliateSearchItemsSortBy;
(function (AmazonAffiliateSearchItemsSortBy) {
    AmazonAffiliateSearchItemsSortBy["Relevance"] = "Relevance";
    AmazonAffiliateSearchItemsSortBy["Featured"] = "Featured";
    AmazonAffiliateSearchItemsSortBy["PriceLowToHigh"] = "Price:LowToHigh";
    AmazonAffiliateSearchItemsSortBy["PriceHighToLow"] = "Price:HighToLow";
})(AmazonAffiliateSearchItemsSortBy = exports.AmazonAffiliateSearchItemsSortBy || (exports.AmazonAffiliateSearchItemsSortBy = {}));
/**
 * This is not an exhaustive list of Amazon browse nodes - instead it is
 * a subset that allows us to, with reasonable accuracy, estimate the
 * commission rate for a given product by looking up its browse node tree.
 */
var AmazonAffiliateBrowseNodeId;
(function (AmazonAffiliateBrowseNodeId) {
    AmazonAffiliateBrowseNodeId["Electronics"] = "493964";
    AmazonAffiliateBrowseNodeId["AmazonDevicesAccessories"] = "2102313011";
    AmazonAffiliateBrowseNodeId["HealthHousehold"] = "3760901";
    AmazonAffiliateBrowseNodeId["GroceryGourmetFood"] = "16310211";
    AmazonAffiliateBrowseNodeId["HomeKitchen"] = "1063498";
    AmazonAffiliateBrowseNodeId["BeautyPersonalCare"] = "3760911";
    AmazonAffiliateBrowseNodeId["Books"] = "1000";
    AmazonAffiliateBrowseNodeId["AudibleBooksOriginals"] = "18145289011";
    AmazonAffiliateBrowseNodeId["KindleStore"] = "133140011";
    AmazonAffiliateBrowseNodeId["MoviesTV"] = "2625373011";
    AmazonAffiliateBrowseNodeId["PrimeVideo"] = "2858778011";
    AmazonAffiliateBrowseNodeId["ToolsHomeImprovement"] = "228013";
    AmazonAffiliateBrowseNodeId["VideoGames"] = "468642";
    AmazonAffiliateBrowseNodeId["ClothingShoesJewelry"] = "7141123011";
    AmazonAffiliateBrowseNodeId["PatioLawnGarden"] = "2972638011";
    AmazonAffiliateBrowseNodeId["MusicalInstruments"] = "11091801";
    AmazonAffiliateBrowseNodeId["ToysGames"] = "165793011";
    AmazonAffiliateBrowseNodeId["OfficeProducts"] = "1064954";
    AmazonAffiliateBrowseNodeId["ArtsCraftsSewing"] = "2617941011";
    AmazonAffiliateBrowseNodeId["PetSupplies"] = "2619533011";
    AmazonAffiliateBrowseNodeId["SportsOutdoors"] = "3375251";
    AmazonAffiliateBrowseNodeId["Automotive"] = "15690151";
    AmazonAffiliateBrowseNodeId["IndustrialScientific"] = "16310091";
    AmazonAffiliateBrowseNodeId["BabyProducts"] = "165796011";
    AmazonAffiliateBrowseNodeId["HandmadeProducts"] = "120955898011";
    AmazonAffiliateBrowseNodeId["LuxuryBeautySelfService"] = "7627913011";
    AmazonAffiliateBrowseNodeId["AppsAndGames"] = "2350149011";
    AmazonAffiliateBrowseNodeId["AlcoholicBeverages"] = "2983386011";
    AmazonAffiliateBrowseNodeId["GiftCards"] = "2238192011";
})(AmazonAffiliateBrowseNodeId = exports.AmazonAffiliateBrowseNodeId || (exports.AmazonAffiliateBrowseNodeId = {}));
/**
 * SearchIndex is how we can filter categories on Amazon.
 * https://webservices.amazon.com/paapi5/documentation/locale-reference/united-states.html
 */
var AmazonSearchIndex;
(function (AmazonSearchIndex) {
    AmazonSearchIndex["All"] = "All";
    AmazonSearchIndex["Apparel"] = "Apparel";
    AmazonSearchIndex["Appliances"] = "Appliances";
    AmazonSearchIndex["ArtsAndCrafts"] = "ArtsAndCrafts";
    AmazonSearchIndex["Automotive"] = "Automotive";
    AmazonSearchIndex["Baby"] = "Baby";
    AmazonSearchIndex["Beauty"] = "Beauty";
    AmazonSearchIndex["Books"] = "Books";
    AmazonSearchIndex["Classical"] = "Classical";
    AmazonSearchIndex["Collectibles"] = "Collectibles";
    AmazonSearchIndex["Computers"] = "Computers";
    AmazonSearchIndex["DigitalMusic"] = "DigitalMusic";
    AmazonSearchIndex["Electronics"] = "Electronics";
    AmazonSearchIndex["FashionBaby"] = "FashionBaby";
    AmazonSearchIndex["FashionBoys"] = "FashionBoys";
    AmazonSearchIndex["FashionGirls"] = "FashionGirls";
    AmazonSearchIndex["FashionMen"] = "FashionMen";
    AmazonSearchIndex["FashionWomen"] = "FashionWomen";
    AmazonSearchIndex["GardenAndOutdoor"] = "GardenAndOutdoor";
    AmazonSearchIndex["Handmade"] = "Handmade";
    AmazonSearchIndex["HealthPersonalCare"] = "HealthPersonalCare";
    AmazonSearchIndex["HomeAndKitchen"] = "HomeAndKitchen";
    AmazonSearchIndex["Industrial"] = "Industrial";
    AmazonSearchIndex["Jewelry"] = "Jewelry";
    AmazonSearchIndex["Luggage"] = "Luggage";
    AmazonSearchIndex["LuxuryBeauty"] = "LuxuryBeauty";
    AmazonSearchIndex["Magazines"] = "Magazines";
    AmazonSearchIndex["MobileAndAccessories"] = "MobileAndAccessories";
    AmazonSearchIndex["MoviesAndTV"] = "MoviesAndTV";
    AmazonSearchIndex["Music"] = "Music";
    AmazonSearchIndex["MusicalInstruments"] = "MusicalInstruments";
    AmazonSearchIndex["OfficeProducts"] = "OfficeProducts";
    AmazonSearchIndex["PetSupplies"] = "PetSupplies";
    AmazonSearchIndex["Photo"] = "Photo";
    AmazonSearchIndex["Shoes"] = "Shoes";
    AmazonSearchIndex["SportsAndOutdoors"] = "SportsAndOutdoors";
    AmazonSearchIndex["ToolsAndHomeImprovement"] = "ToolsAndHomeImprovement";
    AmazonSearchIndex["ToysAndGames"] = "ToysAndGames";
    AmazonSearchIndex["VHS"] = "VHS";
    AmazonSearchIndex["VideoGames"] = "VideoGames";
    AmazonSearchIndex["Watches"] = "Watches";
})(AmazonSearchIndex = exports.AmazonSearchIndex || (exports.AmazonSearchIndex = {}));
var AmazonAffiliateSearchItemsResponseErrorCode;
(function (AmazonAffiliateSearchItemsResponseErrorCode) {
    AmazonAffiliateSearchItemsResponseErrorCode["NoResults"] = "NoResults";
})(AmazonAffiliateSearchItemsResponseErrorCode = exports.AmazonAffiliateSearchItemsResponseErrorCode || (exports.AmazonAffiliateSearchItemsResponseErrorCode = {}));
var AmazonAffiliateRequestType;
(function (AmazonAffiliateRequestType) {
    AmazonAffiliateRequestType["searchItems"] = "SearchItems";
})(AmazonAffiliateRequestType = exports.AmazonAffiliateRequestType || (exports.AmazonAffiliateRequestType = {}));
var AmazonAffiliateRequestMethod;
(function (AmazonAffiliateRequestMethod) {
    AmazonAffiliateRequestMethod["GET"] = "GET";
    AmazonAffiliateRequestMethod["POST"] = "POST";
})(AmazonAffiliateRequestMethod = exports.AmazonAffiliateRequestMethod || (exports.AmazonAffiliateRequestMethod = {}));
