import Vue from 'vue'
import VueMask from 'v-mask'
import { logger } from '@/utils/logger'
import App from './App.vue'
import NetworkUnavailable from './NetworkUnavailable.vue'
import router from './routes/router'
import store from './store'
import './utils/validation'
import { ValidationProvider } from 'vee-validate'
import { i18n } from './utils/i18n'
import { initializeSessionRecording } from './services/marketing'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import './utils/filters'
import {
    ContactsPayload,
    getStoredAccessToken,
    isRunningInNativeApp,
    PermissionResponse,
    PermissionStatus,
    PersistedWebStore,
    saveSessionInfoOnNative,
} from '@/nativeWebInteraction/nativeWebInteraction'
import dayjs from 'dayjs'
import { AppLifecycleEventName, createLifecycleEvent, getSessionInfo } from '@/services/api'
import { startTimeToInteractiveTimer } from '@/utils/timeToInteractive'
import { exposeFunctionsToPlaywright } from '@/playwrightWebInteraction'
import { featureFlagConfig, HomeOwnershipStatus } from 'aven_types'

declare global {
    interface Window {
        logEvent: any
        // TODO: the implementation here of using the plaid cdn is insane from a typing and usability perspective, we need to fix it
        Plaid: any
        plaidOptions: any
        previousPath: string
        // This is the web<>native bridge that React Native exposes if the website is running in a React Native shell.
        ReactNativeWebView?: any
        // Session identifier generated by the mobile app. Will be undefined when accessed in-browser OR in old
        // versions of the native app.
        nativeGeneratedSessionIdentifier?: string
        // If running in a native app shell, the version of the native app
        nativeAppVersion?: string
        // ios or android
        platformOs: string
        // This is where we store the access token that the native shell injects into the web site.
        accessToken?: string | null
        // This is where we store the session access token that the native shell injects into the web site.
        sessionAccessJWT?: string | null
        // This is a unique identifier for the install of the app on the device it's running on. If the app is
        // uninstalled/reinstalled, this changes. On different devices, this ID is different.
        installId?: string | null
        // A key-value object telling the frontend web which native features are available.
        nativeFeatureAvailability?: string | null
        // If the web site is running in a native shell, the native app will be retrieving the session
        // access JWT then passing it to the web using this method.
        receiveSessionAccessJwtFromNative?: (sessionAccessJWT: string) => void
        // Receives user contacts from the device
        receiveContacts?: (contactPayload: ContactsPayload) => void
        // Receives the response of a request for push notif permission
        receivePushPermissionResponse?: (permissionResponse: PermissionResponse) => void
        // Receives push enabled from the device
        receivePushEnabled?: (pushPayload: { pushEnabled: boolean }) => void
        // Receives the Plaid link token form the device
        receivePlaidLinkToken?: (linkTokenPayload: { linkToken: string }) => void
        // If the web site is running in a native shell and the native app receives a deep link
        // then the deep link data will be passed to the web using this method.
        receiveDeepLinkFromNative?: (deepLink: string) => void
        // Receives the last requested date for push notifications from the device
        receiveLastRequestedPushNotificationDate?: (lastRequestedPushNotificationDatePayload: { lastRequestedPushNotificationDate: string }) => void
        // Receives whether we can ask for push notifications again
        receiveCanAskPushAgain?: (canAskPushAgainPayload: { canAskPushAgain: boolean }) => void
        // Receives infomation about the current Expo OTA update
        receiveExpoUpdateInfo?: (pushPayload: { channel: string | null; runtimeVersion: string | null; updateId: string | null }) => void
        // The deep link that was used to open the app
        safeAreaInsetTop?: number
        safeAreaInsetBottom?: number
        // This is a JSON string containing key/value pairs stored persistently on native by the web.
        // On native app load, these key/values are injected into the window for access by the web.
        receivePersistedWebStore?: (pushPayload: PersistedWebStore) => void
        // We're currently experimenting with moving onboarding to react native to
        // avoid potential problems w/ loading the Vue app. The first step is experimenting
        // with phone number entry and full name/email entry, which the react native
        // code will pass via these variables
        onboardingPhoneNumber?: string
        onboardingFirstName?: string
        onboardingLastName?: string
        onboardingEmail?: string
        onboardingOtpSid?: string
        onboardingConsentToCreditOfferDisclosuresHash?: string
        onboardingConsentToMethodFiTosGitHash?: string
        MSStream: any
        // Receives whether we can ask for a generic permission again
        receiveCanAskForContactPermissionAgain?: (payload: { canAskAgain: boolean; permissionStatus: PermissionStatus }) => void
        homeOwnershipStatus?: HomeOwnershipStatus
    }
    interface Crypto {
        // Todo This API exists on all modern browsers, but older versions of Typescript don't
        //  know about it yet. Updating major versions of Typescript might have repercussions
        //  for other libraries we use, so it will be best to update it in its own PR.
        //  See: https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
        // @ts-ignore - TODO: weird typing issue string - OR remove this interface.
        randomUUID: () => string
    }
}

Vue.prototype.$logger = logger // we add logger to the prototype so that we can access it in aven_shared

Vue.prototype.$logEvent = window.logEvent

Vue.prototype.$featureFlagConfig = featureFlagConfig

// we need to register this component globally so that we can use it in aven_shared templates
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

Vue.use(VueMask, {
    // This allows us to use the 'X' character as a regular char.
    // https://github.com/probil/v-mask#default-placeholders
    placeholders: {
        X: null,
    },
})

Vue.directive('keep-scroll-position', {
    bind: function (el) {
        el.addEventListener('scroll', function (event) {
            // @ts-ignore
            event.target.setAttribute('data-vue-keep-scroll-position', event.target.scrollLeft + '-' + event.target.scrollTop)
        })
    },
    inserted: function (el) {
        let i, len, pos, target
        const targets = el.querySelectorAll('[data-vue-keep-scroll-position]')
        if (targets.length > 0) {
            for (i = 0, len = targets.length; i < len; i++) {
                target = targets[i]
                // @ts-ignore
                pos = target.getAttribute('data-vue-keep-scroll-position').split('-')
                // @ts-ignore
                target.scrollLeft = pos[0]
                // @ts-ignore
                target.scrollTop = pos[1]
            }
        } else {
            if (el.hasAttribute('data-vue-keep-scroll-position')) {
                // @ts-ignore
                pos = el.getAttribute('data-vue-keep-scroll-position').split('-')
                // @ts-ignore
                el.scrollLeft = pos[0]
                // @ts-ignore
                el.scrollTop = pos[1]
            }
        }
    },
})

// https://day.js.org/docs/en/plugin/localized-format
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export const currentContextForLogging: any = {}

Vue.mixin({
    created() {
        try {
            const name = this.name
            const vnode = this.$vnode
            const uid = this.uid as string
            if (name || vnode?.tag || uid) {
                currentContextForLogging[name || vnode?.tag || uid] = this
            }
        } catch (e) {
            logger.fatal(`error adding logging context of component name: ${this?.name} vnode: ${this?.$vnode?.tag} uid: ${this?.uid}`)
        }
    },
})

const maybeCreateLifecycleEvent = (eventName: AppLifecycleEventName) => {
    if (!window.nativeGeneratedSessionIdentifier) {
        logger.log(`Aven Advisor web doesn't have an nativeGeneratedSessionIdentifier, so not creating lifecycle event ${eventName}`)
        return
    }
    logger.log(`Aven Advisor web has nativeGeneratedSessionIdentifier ${window.nativeGeneratedSessionIdentifier}, so creating lifecycle event ${eventName}`)
    createLifecycleEvent(window.nativeGeneratedSessionIdentifier, eventName, {
        nativeAppVersion: window.nativeAppVersion,
        platformOs: window.platformOs,
        appInstallId: window.installId,
        sessionId: appSessionStorage.getItem(localStorageKey.sessionId),
    })
        .then(() => {
            logger.log(`Aven Advisor web created lifecycle event ${eventName} for nativeGeneratedSessionIdentifier ${window.nativeGeneratedSessionIdentifier}`)
        })
        .catch((e) => {
            logger.fatal(`Error creating lifecycle event ${eventName} from Aven Advisor web`, e)
        })
}
maybeCreateLifecycleEvent(AppLifecycleEventName.avenAdvisorNativeLoadedWebApp)

let isMounted = false
const initVue = () => {
    if (isMounted) {
        logger.fatal(`Aven Advisor Vue is already mounted. Not mounting again ...`)
        return
    }

    if (isRunningInNativeApp()) {
        const accessToken = getStoredAccessToken()
        logger.log(`Aven Advisor web is running in native shell. Examining access token from native`)
        if (accessToken) {
            logger.log(`Aven Advisor found access token from native: ${accessToken}`)
            store.commit('updateJwtTokensWithoutSavingToNative', { accessJWT: accessToken })
        } else {
            logger.log(`Aven Advisor did not find access token from native`)
        }
    }

    // get sessionId from localStorage and update store for usage preference
    const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
    const sessionAccessJWT = appSessionStorage.getItem(localStorageKey.sessionAccessJWT)
    store.commit('updateSessionInfo', { sessionId, sessionAccessJWT })

    // set the access token so that it gets reused for subsequent get session requests
    window.sessionAccessJWT = sessionAccessJWT

    // Send the session access token to the native side to be used for logging
    saveSessionInfoOnNative(sessionAccessJWT, sessionId)
    // Listen for + log when vue-loaded is fired (helps with debugging)
    document.addEventListener('vue-loaded', function () {
        logger.log('Vue loaded!')
    })

    // Add global beforeunload event listener
    window.addEventListener('beforeunload', () => {
        logger.log('Page is being refreshed or closed')
        window.logEvent('event_advisor_page_refreshed_or_closed')
    })

    logger.log('Enabling vue...')
    new Vue({
        router,
        i18n,
        store,
        render: (h) => h(App),
    }).$mount('#app')
    isMounted = true

    maybeCreateLifecycleEvent(AppLifecycleEventName.avenAdvisorNativeWebAppInitializedVue)
    initializeSessionRecording()

    const vueLoadedEvent = new Event('vue-loaded')
    document.dispatchEvent(vueLoadedEvent)
    exposeFunctionsToPlaywright()
}

const initVueNetworkUnavailable = () => {
    if (isMounted) {
        logger.fatal(`Aven Advisor Vue is already mounted. Not mounting 'network unavailable' again ...`)
        return
    }

    // Don't bother sending to the backend, unlikely to succeed + generates tons of console errors
    logger.setNetworkLogging(false)
    logger.log('Enabling vue (network unavailable)...')
    new Vue({
        i18n,
        render: (h) => h(NetworkUnavailable),
    }).$mount('#app')
    isMounted = true

    maybeCreateLifecycleEvent(AppLifecycleEventName.avenAdvisorNativeWebAppInitializedVue)
}

const getSessionInfoWithTimeout = () => {
    getSessionInfo()
        .then(() => {
            logger.log(`Initializing Aven Advisor Vue app.`)
            initVue()
        })
        .catch(() => {
            logger.log('Network is unavailable on Aven Advisor so showing corresponding Vue app')
            initVueNetworkUnavailable()
        })
}

function init() {
    startTimeToInteractiveTimer()
    // The old version of the app (version 70 and below) retrieves sessionAccessJWT *before*
    // trying to load the web, then injects the token into the window. This can also occur on
    // the new app if the session loads before the web loads.
    if (isRunningInNativeApp() && window.sessionAccessJWT) {
        logger.log(`Aven Advisor is running in native shell and already has window.sessionAccessJWT, so getting Aven Advisor session info from backend immediately`)
        getSessionInfoWithTimeout()
    }
    // The new app (versions above 70) load the web while they are retrieving sessionAccessJWT,
    // so in that case, we'll set a window callback to retrieve the JWT before continuing startup.
    else if (isRunningInNativeApp()) {
        logger.log(`Aven Advisor is running in native shell but doesn't have window.sessionAccessJWT, so waiting for the native shell to pass session info to the web`)
        // DO NOT change method name; native app expects this to exist. Even if you're updating the native app,
        // you need to keep this method around for backwards compatibility.
        const promiseToReceiveSessionAccessJwtFromNative = new Promise<void>((resolve, reject) => {
            window.receiveSessionAccessJwtFromNative = (sessionAccessJWT: string | null) => {
                try {
                    // It might seem a bit odd to get session info from the backend after the app has already passed it
                    // to the web. However, we want the backend to be able to pass back the latest helocCardInfo so we
                    // need to make this API call. Note that since we pass the SessionAuthorization header with the request
                    // the current session just gets updated, rather than a new session being created.
                    logger.log(`Received Aven Advisor sessionAccessJWT from native. Setting it on window and proceeding to get session info from backend`)
                    window.sessionAccessJWT = sessionAccessJWT
                    resolve()
                } catch (e) {
                    logger.fatal(`Error during receiveSessionAccessJwtFromNative`, e)
                    reject(e)
                }
            }
        })
        // This timeout is between the P75 and P95 times that it takes the native app to retrieve a session.
        const timeoutMillis = 5_000
        const timeoutToReceiveSessionAccessJwtFromNative = new Promise((resolve) => setTimeout(resolve, timeoutMillis))

        // We'll wait for a sessionAccessJWT from native OR timeoutMillis, then go forward with the the call to
        // getSessionInfoWithTimeout(), which results in the Vue app getting loaded
        Promise.race([promiseToReceiveSessionAccessJwtFromNative, timeoutToReceiveSessionAccessJwtFromNative]).finally(() => {
            window.receiveSessionAccessJwtFromNative = undefined
            logger.log(
                `Aven Advisor web is calling getSessionInfoWithTimeout. window.sessionAccessJWT = ${window.sessionAccessJWT}, which indicates native session ID call ${
                    window.sessionAccessJWT ? 'succeeded' : 'failed'
                }`
            )
            getSessionInfoWithTimeout()
        })
    } else {
        logger.log(`Aven Advisor is not running in native shell, so getting Aven Advisor session info from backend immediately`)
        getSessionInfoWithTimeout()
    }
}

init()
