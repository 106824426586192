"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromotionName = void 0;
/* @TranslateToPython @TranslateToEnum */
var PromotionName;
(function (PromotionName) {
    PromotionName["freeAmazonPrime"] = "freeAmazonPrime";
    PromotionName["amazonTenDollarCredit"] = "amazonTenDollarCredit";
    PromotionName["amazonTwentyFiveDollarCredit"] = "amazonTwentyFiveDollarCredit";
    PromotionName["taxPaymentCredit"] = "taxPaymentCredit";
    PromotionName["costcoFreeMembership"] = "costcoFreeMembership";
})(PromotionName = exports.PromotionName || (exports.PromotionName = {}));
