"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RakutenResponseFormat = exports.RakutenRequestMethod = exports.RakutenRequestType = void 0;
var RakutenRequestType;
(function (RakutenRequestType) {
    RakutenRequestType["getToken"] = "get_token";
    RakutenRequestType["getAdvertiser"] = "get_advertiser";
    RakutenRequestType["getProducts"] = "get_products";
    RakutenRequestType["getPartnerAdvertiser"] = "get_partner_advertiser";
    RakutenRequestType["getCoupons"] = "get_coupons";
})(RakutenRequestType = exports.RakutenRequestType || (exports.RakutenRequestType = {}));
var RakutenRequestMethod;
(function (RakutenRequestMethod) {
    RakutenRequestMethod["get"] = "get";
    RakutenRequestMethod["post"] = "post";
})(RakutenRequestMethod = exports.RakutenRequestMethod || (exports.RakutenRequestMethod = {}));
var RakutenResponseFormat;
(function (RakutenResponseFormat) {
    RakutenResponseFormat["json"] = "json";
    RakutenResponseFormat["xml"] = "xml";
})(RakutenResponseFormat = exports.RakutenResponseFormat || (exports.RakutenResponseFormat = {}));
