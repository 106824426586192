"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenFixedInstallmentPlanEligibilityResult = exports.AmortizationLoanType = void 0;
var AmortizationLoanType;
(function (AmortizationLoanType) {
    AmortizationLoanType["fixedFinanceCharge"] = "fixedFinanceCharge";
    AmortizationLoanType["simpleInterest"] = "simpleInterest";
    AmortizationLoanType["none"] = "none";
})(AmortizationLoanType = exports.AmortizationLoanType || (exports.AmortizationLoanType = {}));
var AvenFixedInstallmentPlanEligibilityResult;
(function (AvenFixedInstallmentPlanEligibilityResult) {
    AvenFixedInstallmentPlanEligibilityResult["ENABLED"] = "ENABLED";
    AvenFixedInstallmentPlanEligibilityResult["DISABLED_DUE_TO_TEASER"] = "DISABLED_DUE_TO_TEASER";
    AvenFixedInstallmentPlanEligibilityResult["DISABLED_DUE_TO_HCM"] = "DISABLED_DUE_TO_HCM";
    AvenFixedInstallmentPlanEligibilityResult["DISABLED_DUE_TO_PENDING_PRIME_V2_OPT_OUT"] = "DISABLED_DUE_TO_PENDING_PRIME_V2_OPT_OUT";
    AvenFixedInstallmentPlanEligibilityResult["DISABLED_DUE_TO_PRODUCT"] = "DISABLED_DUE_TO_PRODUCT";
    AvenFixedInstallmentPlanEligibilityResult["DISABLED_DUE_TO_INADEQUATE_AMOUNT"] = "DISABLED_DUE_TO_INADEQUATE_AMOUNT";
})(AvenFixedInstallmentPlanEligibilityResult = exports.AvenFixedInstallmentPlanEligibilityResult || (exports.AvenFixedInstallmentPlanEligibilityResult = {}));
