"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mortgageRefiClosingFees = exports.featureFlagConfig = void 0;
/* @TranslateToPython @TranslateToEnum */
exports.featureFlagConfig = {
    paidReferrals: true,
    creditOffers: false,
    experianBoostEnabled: false,
};
// Default values for mortgage refi closing fee breakdowns
exports.mortgageRefiClosingFees = {
    originationFeeRate: 0.01,
    appraisalFee: 500,
    titleInsuranceAndSearch: 1000,
    creditReportFee: 50,
    attorneyFees: 1000,
    surveyFee: 500,
    recordingFee: 100,
    underwritingFee: 500,
    escrowDeposit: 4000,
    daysUntilFirstPayment: 15,
};
