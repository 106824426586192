"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.referralFromText = exports.ReferralFrom = exports.NotarySessionRole = void 0;
var NotarySessionRole;
(function (NotarySessionRole) {
    NotarySessionRole["notary"] = "Notary";
    NotarySessionRole["applicant"] = "Applicant";
    NotarySessionRole["witness"] = "Witness";
    NotarySessionRole["attorney"] = "Attorney";
    NotarySessionRole["plotter"] = "Plotter";
    NotarySessionRole["visitor"] = "Visitor";
    NotarySessionRole["mlo"] = "MLO";
})(NotarySessionRole = exports.NotarySessionRole || (exports.NotarySessionRole = {}));
var ReferralFrom;
(function (ReferralFrom) {
    ReferralFrom["directMail"] = "directMail";
    ReferralFrom["facebookOrInstagram"] = "facebookOrInstagram";
    ReferralFrom["google"] = "google";
    ReferralFrom["tvAd"] = "tvAd";
    ReferralFrom["youtube"] = "youtube";
    ReferralFrom["radio"] = "radio";
    ReferralFrom["podcast"] = "podcast";
    ReferralFrom["creditKarma"] = "creditKarma";
    ReferralFrom["friend"] = "friend";
    ReferralFrom["dontRemember"] = "dontRemember";
    ReferralFrom["other"] = "other";
})(ReferralFrom = exports.ReferralFrom || (exports.ReferralFrom = {}));
exports.referralFromText = {
    [ReferralFrom.directMail]: 'Mail',
    [ReferralFrom.facebookOrInstagram]: 'Facebook/Instagram',
    [ReferralFrom.google]: 'Google',
    [ReferralFrom.tvAd]: 'TV Ad',
    [ReferralFrom.youtube]: 'YouTube',
    [ReferralFrom.radio]: 'Radio',
    [ReferralFrom.podcast]: 'Podcast',
    [ReferralFrom.creditKarma]: 'Credit Karma',
    [ReferralFrom.friend]: 'From a friend',
    [ReferralFrom.dontRemember]: "Don't remember",
    [ReferralFrom.other]: 'Other',
};
