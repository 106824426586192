import { Contact, WebViewMessageData } from 'aven_types'
import {
    canGetWhetherAppCanAskForContactPermissionAgain,
    canGetWhetherAppCanAskPushPermissionAgain,
    canReceiveSessionInfoObject,
    canReturnPushPermissionResponse,
    getIsSetSafeAreaPaddingAvailable,
    isCheckPushPermissionEnabled,
} from '@/nativeWebInteraction/nativeFeatureAvailability'
import { inspect, logger } from '@/utils/logger'
import { Optional, WebViewMessageEventName } from 'aven_types'
import { appRoutePaths } from '@/routes/appRoutes'
import assert from 'assert'

export const getStoredAccessToken = (): string | null | undefined => {
    // If the user is logged in (i.e. the native app has an access token) the web
    // will receive it at this window variable. See aven_apps/aven_score/src/Web/index.js
    return window.accessToken
}

export const getAppInstallId = (): string | null | undefined => {
    // If the Aven Advisor website is running in a native shell, the shell will set
    // the installId at this window variable. See aven_apps/aven_score/src/Web/index.js
    return window.installId
}

export const isRunningInNativeApp = (): boolean => {
    // If the web site is running in the React Native Advisor app, we will have exposed the interface
    // for the web to communicate back to it. See:
    // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Guide.md#the-windowreactnativewebviewpostmessage-method-and-onmessage-prop
    // Note that comment below silences lint; ReactNativeWebView will be available when running in React Native app
    const isRunningInNativeApp = !!window.ReactNativeWebView
    logger.log(`Is Aven Advisor web running in native app? ${isRunningInNativeApp}`)
    return isRunningInNativeApp
}

enum PlatformOs {
    ios = 'ios',
    android = 'android',
}

export const isIosNativeApp = (): boolean => {
    return isRunningInNativeApp() && window.platformOs === PlatformOs.ios
}

export const isAndroidNativeApp = (): boolean => {
    return isRunningInNativeApp() && window.platformOs === PlatformOs.android
}

const sendMessageToNativeApp = (dataToSend: WebViewMessageData) => {
    const stringData = JSON.stringify(dataToSend)
    if (window.ReactNativeWebView) {
        logger.log(`Sending a message from Advisor web to app: ${stringData}`)
        // Note that comment below silences lint; ReactNativeWebView will be available when running in React Native app
        window.ReactNativeWebView.postMessage(stringData)
    } else {
        logger.log(`Aven Advisor not running in native shell, so not sending message to native app: ${stringData}`)
    }
}

/**
 * A copy of PermissionResponse in aven_apps/aven_score/node_modules/expo-modules-core/src/PermissionsInterface.ts
 * This is what React Native sends back to the web when we request permission.
 */
export interface PermissionResponse {
    /**
     * Determines the status of the permission.
     */
    status: PermissionStatus
    /**
     * Determines time when the permission expires. Currently, all permissions are granted permanently.
     */
    expires: 'never' | number
    /**
     * A convenience boolean that indicates if the permission is granted.
     */
    granted: boolean
    /**
     * Indicates if user can be asked again for specific permission. If not, one should be directed to the Settings app in order to enable/disable the permission.
     */
    canAskAgain: boolean
}

export const requestPushPermission = async (): Promise<PermissionResponse | undefined> => {
    logger.log(`Aven Advisor web is requesting push permission`)
    return new Promise<PermissionResponse | undefined>((resolve, reject) => {
        try {
            if (canReturnPushPermissionResponse()) {
                logger.log(`Aven Advisor native indicates it can return push permission response, so web is setting up to receive that`)
                window.receivePushPermissionResponse = (permissionResponse: PermissionResponse) => {
                    logger.info(`Aven Advisor web received push notif permission response from native: ${JSON.stringify(permissionResponse)}`)
                    resolve(permissionResponse)
                }
                sendMessageToNativeApp({
                    eventName: WebViewMessageEventName.REQUEST_PUSH_PERMISSION,
                    windowCallbackMethodName: 'receivePushPermissionResponse',
                })
            } else {
                logger.log(`Aven Advisor native does not indicate it can return push permission response, so web is immediately resolving the requestPushPermission promise`)
                sendMessageToNativeApp({
                    eventName: WebViewMessageEventName.REQUEST_PUSH_PERMISSION,
                })
                resolve(undefined)
            }
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receivePushPermissionResponse = undefined
    })
}

export const getPushAlreadyEnabled = async (): Promise<boolean> => {
    logger.info(`Aven Advisor web is getting whether push is already enabled`)
    if (!isCheckPushPermissionEnabled()) {
        return false
    }
    logger.log(`Getting current native push permissions`)
    return new Promise<boolean>((resolve, reject) => {
        try {
            window.receivePushEnabled = (pushPayload: { pushEnabled: boolean }) => {
                logger.log(`Got native push permissions, push enabled: ${pushPayload.pushEnabled}`)
                resolve(pushPayload.pushEnabled)
            }
            sendMessageToNativeApp({
                eventName: WebViewMessageEventName.GET_PUSH_ENABLED,
                windowCallbackMethodName: 'receivePushEnabled',
            })
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receivePushEnabled = undefined
    })
}

export const trySendAppsFlyerSignupEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer signup event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_SIGN_UP_EVENT })
}

export const trySendAppsFlyerHomeownerSignupEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner signup event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_SIGN_UP_EVENT })
}

export const trySendAppsFlyerHelocSignupEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer HELOC signup event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HELOC_SIGN_UP_EVENT })
}

export const trySendAppsFlyerCreditCardSignupEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer credit card signup event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_CREDIT_CARD_SIGN_UP_EVENT })
}

export const trySendAppsFlyerPhoneNumberSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer phone number submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_PHONE_NUMBER_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerOtpSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer OTP submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_OTP_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerSsnSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer SSN submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_SSN_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerPiiSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer PII submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_PII_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerKbaSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer KBA success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_KBA_SUCCESS_EVENT })
}

export const trySendAppsFlyerNotificationPermissionGrantedEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer notification permission granted event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_NOTIFICATION_PERMISSION_GRANTED_EVENT })
}

export const trySendAppsFlyerStatedGoalSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer stated goal submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_STATED_GOAL_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerStatedGoalBudgetSpendTrackingSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer stated goal budget spend tracking submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_STATED_GOAL_BUDGET_SPEND_TRACKING_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerStatedGoalLienReportSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer stated goal lien report submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_STATED_GOAL_LIEN_REPORT_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerStatedGoalFindSubscriptionsSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer stated goal find subscriptions submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_STATED_GOAL_FIND_SUBSCRIPTIONS_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerStatedGoalCreditScoreSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer stated goal credit score submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_STATED_GOAL_CREDIT_SCORE_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerStatedGoalOtherSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer stated goal other submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_STATED_GOAL_OTHER_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerPhoneNumberSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner phone number submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_PHONE_NUMBER_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerOtpSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner OTP submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_OTP_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerSsnSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner SSN submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_SSN_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerPiiSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner PII submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_PII_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerKbaSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner KBA success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_KBA_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerNotificationPermissionGrantedEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner notification permission granted event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_NOTIFICATION_PERMISSION_GRANTED_EVENT })
}

export const trySendAppsFlyerHomeownerStatedGoalSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner stated goal submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_STATED_GOAL_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerStatedGoalBudgetSpendTrackingSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner stated goal budget spend tracking submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_STATED_GOAL_BUDGET_SPEND_TRACKING_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerStatedGoalLienReportSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner stated goal lien report submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_STATED_GOAL_LIEN_REPORT_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerStatedGoalFindSubscriptionsSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner stated goal find subscriptions submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_STATED_GOAL_FIND_SUBSCRIPTIONS_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerStatedGoalCreditScoreSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner stated goal credit score submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_STATED_GOAL_CREDIT_SCORE_SUBMIT_SUCCESS_EVENT })
}

export const trySendAppsFlyerHomeownerStatedGoalOtherSubmitSuccessEvent = () => {
    logger.log(`Aven Advisor web is trying to send AppsFlyer homeowner stated goal other submit success event`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.TRY_SEND_APPSFLYER_HOMEOWNER_STATED_GOAL_OTHER_SUBMIT_SUCCESS_EVENT })
}

export const requestTrackingPermission = () => {
    logger.log(`Aven Advisor web is requesting tracking permission`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.REQUEST_TRACKING_PERMISSION })
}

export const saveAccessTokenOnNative = (accessToken: string | null) => {
    logger.log(`Aven Advisor web is trying to save access token on native: ${accessToken}`)
    window.accessToken = accessToken
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SAVE_ACCESS_TOKEN,
        payload: accessToken,
    })
}

export const saveSessionInfoOnNative = (accessToken: string | null, sessionId: string | null) => {
    logger.log(`Aven Advisor web is trying to save session access token on native: ${accessToken}`)
    if (canReceiveSessionInfoObject()) {
        sendMessageToNativeApp({
            eventName: WebViewMessageEventName.SAVE_SESSION_INFO,
            payload: {
                accessToken,
                sessionId,
            },
        })
    }
}

export const openUrlInBrowser = (url: string) => {
    logger.log(`Aven Advisor web is trying to open URL in browser: ${url}`)
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.OPEN_URL_IN_BROWSER,
        payload: {
            url,
        },
    })
}

// Keep in sync with AmplitudePayload in aven_apps/aven_score/src/nativeWebInteraction/nativeWebInteraction.ts
interface AmplitudePayload {
    eventName: string
    payload: object
}

export const logAmplitudeEvent = (amplitudePayload: AmplitudePayload) => {
    logger.log(`Aven Advisor web is logging amplitude event with name: ${WebViewMessageEventName.LOG_AMPLITUDE_EVENT}, and payload: ${inspect(amplitudePayload)}`)
    sendMessageToNativeApp({ eventName: WebViewMessageEventName.LOG_AMPLITUDE_EVENT, payload: amplitudePayload })
}

// Keep in sync with DocumentType in aven_apps/aven_score/src/downloadManager/downloadManager.ts
// See aven_backend/src/util/legalConstants.ts for all possible types
// that could potentially get added here.
export enum DocumentType {
    creditScoreDisclosure = 'CreditScoreDisclosure',
    earlyHELOCDisclosure = 'EarlyHELOCDisclosure',
    pricingAndTerms = 'PricingAndTerms',
}

// Keep in sync with SaveAndShareDocumentPayload in aven_apps/aven_score/src/nativeWebInteraction/nativeWebInteraction.ts
interface SaveAndShareDocumentPayload {
    docType: DocumentType
}

export const saveAndShareDocument = ({ docType }: SaveAndShareDocumentPayload) => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SAVE_AND_SHARE_DOCUMENT,
        payload: {
            docType,
        },
    })
}

// Copy of aven_apps/aven_score/node_modules/expo-modules-core/src/PermissionsInterface.ts
export enum PermissionStatus {
    GRANTED = 'granted',
    UNDETERMINED = 'undetermined',
    DENIED = 'denied',
}

// Keep in sync with ContactsPayload in aven_apps/aven_score/src/nativeWebInteraction/nativeWebInteraction.ts
export interface ContactsPayload {
    contactsApiAvailable: boolean
    permissionStatus: PermissionStatus
    canAskPermissionAgain?: boolean
    contacts: Contact[]
}

export const retrieveContacts = async (): Promise<ContactsPayload> => {
    return new Promise<ContactsPayload>((resolve, reject) => {
        try {
            window.receiveContacts = (contactPayload: ContactsPayload) => {
                resolve(contactPayload)
            }
            sendMessageToNativeApp({
                eventName: WebViewMessageEventName.RETRIEVE_CONTACTS,
                windowCallbackMethodName: 'receiveContacts',
            })
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receiveContacts = undefined
    })
}

export const setLastRequestedTimeForNotifPermission = () => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SET_NOTIF_PERM_ASKED_TIME,
    })
}

export const getLastRequestedTimeForNotifPermission = async (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        try {
            window.receiveLastRequestedPushNotificationDate = (lastRequestedPushNotificationDatePayload: { lastRequestedPushNotificationDate: string }) => {
                resolve(lastRequestedPushNotificationDatePayload.lastRequestedPushNotificationDate)
            }
            sendMessageToNativeApp({
                eventName: WebViewMessageEventName.GET_NOTIF_PERM_ASKED_TIME,
                windowCallbackMethodName: 'receiveLastRequestedPushNotificationDate',
            })
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receiveLastRequestedPushNotificationDate = undefined
    })
}

export const getCanAskForPushPermissionAgain = async (defaultValue: boolean): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        try {
            if (canGetWhetherAppCanAskPushPermissionAgain()) {
                logger.log(`Aven Advisor native indicates it can return whether we can ask for push permission again, so web is setting up to receive that`)
                window.receiveCanAskPushAgain = (canAskPushAgainPayload: { canAskPushAgain: boolean }) => {
                    logger.info(`Aven Advisor web received whether it can request push notif permission again from native: ${JSON.stringify(canAskPushAgainPayload)}`)
                    resolve(canAskPushAgainPayload.canAskPushAgain)
                }
                sendMessageToNativeApp({
                    eventName: WebViewMessageEventName.GET_CAN_ASK_PUSH_AGAIN,
                    windowCallbackMethodName: 'receiveCanAskPushAgain',
                })
            } else {
                logger.log(`Aven Advisor native indicates it cannot return whether we can ask for push permission again, so web is using the default value of ${defaultValue}`)
                resolve(defaultValue)
            }
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receiveCanAskPushAgain = undefined
    })
}

export const canAskForContactPermissionAgain = async (): Promise<{ canAskAgain: boolean; permissionStatus: PermissionStatus }> => {
    return new Promise<{ canAskAgain: boolean; permissionStatus: PermissionStatus }>((resolve, reject) => {
        try {
            if (canGetWhetherAppCanAskForContactPermissionAgain()) {
                logger.log(`Aven Advisor native indicates it can return whether we can ask for contact permission again, so web is setting up to receive that`)
                window.receiveCanAskForContactPermissionAgain = (payload: { canAskAgain: boolean; permissionStatus: PermissionStatus }) => {
                    logger.info(`Aven Advisor web received whether it can ask for contact permission again from native: ${JSON.stringify(payload)}`)
                    resolve(payload)
                }
                sendMessageToNativeApp({
                    eventName: WebViewMessageEventName.CAN_ASK_FOR_CONTACT_PERMISSION_AGAIN,
                    windowCallbackMethodName: 'receiveCanAskForContactPermissionAgain',
                })
            } else {
                logger.log(`Aven Advisor native indicates it cannot return whether we can ask for push permission again, so web is using the default value of true`)
                resolve({ canAskAgain: true, permissionStatus: PermissionStatus.UNDETERMINED })
            }
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receiveCanAskForContactPermissionAgain = undefined
    })
}

export const openExternalWebView = async (url: string, title?: string): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.OPEN_EXTERNAL_WEB_VIEW,
        payload: {
            url,
            title: title ?? '',
        },
    })
}

export const closeExternalWebView = async (): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.CLOSE_EXTERNAL_WEB_VIEW,
    })
}

export const openShareSheet = async (shareContent: { title?: string | undefined; message: string } | { title?: string | undefined; url: string }): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.OPEN_SHARE_SHEET,
        payload: {
            shareContent,
        },
    })
}

// https://docs.expo.dev/versions/latest/sdk/sms/#smsoptions
export const openSMSShareSheet = async (shareContent: { addresses: string[]; message: string; options?: object }): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.OPEN_SMS_SHARE_SHEET,
        payload: {
            shareContent,
        },
    })
}

// https://docs.expo.dev/versions/latest/sdk/mail-composer/#types
export const openMailShareSheet = async (shareContent: {
    attachments?: string[]
    bccRecipients?: string[]
    body?: string
    ccRecipients?: string[]
    isHtml?: boolean
    recipients?: string[]
    subject?: string
}): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.OPEN_MAIL_SHARE_SHEET,
        payload: {
            shareContent,
        },
    })
}

export const saveCurrentURLPath = async (path: string | null): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SAVE_URL_PATH,
        payload: {
            path,
        },
    })
}

export const savePlaidLinkToken = async (linkToken: string | null): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SAVE_PLAID_LINK_TOKEN,
        payload: {
            linkToken,
        },
    })
}

export const getPlaidLinkToken = async (): Promise<string> => {
    logger.log(`Getting plaid link token from native storage`)
    return new Promise<string>((resolve, reject) => {
        try {
            window.receivePlaidLinkToken = (linkTokenPayload: { linkToken: string }) => {
                logger.log(`Got plaid link token from native storage: ${linkTokenPayload.linkToken}`)
                resolve(linkTokenPayload.linkToken)
            }
            sendMessageToNativeApp({
                eventName: WebViewMessageEventName.GET_PLAID_LINK_TOKEN,
                windowCallbackMethodName: 'receivePlaidLinkToken',
            })
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receivePlaidLinkToken = undefined
    })
}

// Keep in sync with PlaidLinkState in aven_apps/aven_score/src/Redux/reduxSlices.ts
interface PlaidLinkState {
    plaidPublicToken: string | null
    accountId: string | null
    institutionName: string | null
    errorMessage: string | null
}

export const sendPlaidLinkStateToNative = async (linkState: PlaidLinkState): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SEND_PLAID_LINK_SUCCESS_DATA,
        payload: linkState,
    })
}

export const sendToastMessageToNative = async (message: string): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.TOAST_MESSAGE,
        payload: {
            message,
        },
    })
}

export interface PersistedWebStore {
    plaidLinkPathToReturnTo: Optional<string>
    dismissedMethodFiUpdateBanner: boolean
    dismissedShoppingTabNuxModal: boolean
}

const getPersistedWebStore = async (): Promise<PersistedWebStore> => {
    logger.log(`Getting persistedWebStore from native`)
    return new Promise<PersistedWebStore>((resolve, reject) => {
        try {
            window.receivePersistedWebStore = (payload: PersistedWebStore) => {
                logger.log(`Got persisted web store: ${JSON.stringify(payload)}`)
                resolve(payload)
            }
            sendMessageToNativeApp({
                eventName: WebViewMessageEventName.GET_PERSISTED_WEB_STORE,
                windowCallbackMethodName: 'receivePersistedWebStore',
            })
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receivePersistedWebStore = undefined
    })
}

const setPersistedWebStoreValue = async (key: keyof PersistedWebStore, value: string | number | boolean | null): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SET_PERSISTED_WEB_STORE_VALUE,
        payload: {
            [key]: value,
        },
    })
}

export const setApplySafeArea = async (payload: { top: boolean; bottom: boolean }): Promise<void> => {
    if (!getIsSetSafeAreaPaddingAvailable()) {
        logger.log(`setApplySafeArea is not available on this version`)
        return
    }
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.SET_APPLY_SAFE_AREA,
        payload,
    })
}

export const getPlaidLinkPathToReturnTo = async (): Promise<Optional<string>> => {
    const persistedWebStore = await getPersistedWebStore()
    return persistedWebStore?.plaidLinkPathToReturnTo
}

export const setPlaidLinkPathToReturnTo = async (path: string | null): Promise<void> => {
    if (path) {
        assert(Object.values(appRoutePaths).includes(path), 'Path must be a valid app route path')
    }
    await setPersistedWebStoreValue('plaidLinkPathToReturnTo', path)
}

export const getDismissedMethodFiUpdateBanner = async (): Promise<boolean> => {
    const persistedWebStore = await getPersistedWebStore()
    return persistedWebStore?.dismissedMethodFiUpdateBanner ?? false
}

export const dismissMethodFiUpdateBanner = async (): Promise<void> => {
    await setPersistedWebStoreValue('dismissedMethodFiUpdateBanner', true)
}

export const getDismissedShoppingTabNuxModal = async (): Promise<boolean> => {
    const persistedWebStore = await getPersistedWebStore()
    return persistedWebStore?.dismissedShoppingTabNuxModal ?? false
}

export const dismissShoppingTabNuxModal = async (): Promise<void> => {
    await setPersistedWebStoreValue('dismissedShoppingTabNuxModal', true)
}

export const tellNativeThatWebIsInteractive = async (): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.WEB_IS_INTERACTIVE,
    })
}

export const getExpoUpdateInfo = async (): Promise<{ channel: string | null; runtimeVersion: string | null; updateId: string | null }> => {
    logger.log(`Getting information about expo update from native`)
    return new Promise<{ channel: string | null; runtimeVersion: string | null; updateId: string | null }>((resolve, reject) => {
        try {
            window.receiveExpoUpdateInfo = (pushPayload: { channel: string | null; runtimeVersion: string | null; updateId: string | null }) => {
                logger.log(`Got information about currently running Expo update: ${JSON.stringify(pushPayload)}`)
                resolve(pushPayload)
            }
            sendMessageToNativeApp({
                eventName: WebViewMessageEventName.GET_EXPO_UPDATE_INFO,
                windowCallbackMethodName: 'receiveExpoUpdateInfo',
            })
        } catch (e) {
            reject(e)
        }
    }).finally(() => {
        window.receiveExpoUpdateInfo = undefined
    })
}

export const openAppSettings = async (): Promise<void> => {
    sendMessageToNativeApp({
        eventName: WebViewMessageEventName.OPEN_APP_SETTINGS,
    })
}
