"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanApplicationType = void 0;
/** @TranslateToPython @TranslateToEnum */
var LoanApplicationType;
(function (LoanApplicationType) {
    LoanApplicationType["AVEN_ORIGINATION"] = "AVEN_ORIGINATION";
    LoanApplicationType["AVEN_MLO_ORIGINATION"] = "AVEN_MLO_ORIGINATION";
    LoanApplicationType["AVEN_HOME_REATTACH"] = "AVEN_HOME_REATTACH";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE"] = "AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE_DRYRUN"] = "AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE_DRYRUN";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_APR_REDUCTION"] = "AVEN_HOME_REUNDERWRITING_APR_REDUCTION";
    LoanApplicationType["AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP"] = "AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP";
    LoanApplicationType["AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP_DRYRUN"] = "AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP_DRYRUN";
    LoanApplicationType["_AVEN_WAITLIST"] = "_AVEN_WAITLIST";
    LoanApplicationType["_AVEN_HOME_REUNDERWRITING_ANNUAL_RENEWAL"] = "AVEN_HOME_REUNDERWRITING_ANNUAL_RENEWAL";
    LoanApplicationType["MORTGAGE_BROKERING"] = "MORTGAGE_BROKERING";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_POLICY_CHANGE"] = "AVEN_HOME_REUNDERWRITING_POLICY_CHANGE";
    LoanApplicationType["AVEN_UCC_ORIGINATION"] = "AVEN_UCC_ORIGINATION";
    LoanApplicationType["AVEN_MORTGAGE_ORIGINATION"] = "AVEN_MORTGAGE_ORIGINATION";
})(LoanApplicationType = exports.LoanApplicationType || (exports.LoanApplicationType = {}));
