"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeaserPromoType = void 0;
/** @TranslateToPython @TranslateToEnum */
var TeaserPromoType;
(function (TeaserPromoType) {
    TeaserPromoType["NO_TEASER_PROMO"] = "NO_TEASER_PROMO";
    TeaserPromoType["TEASER_ZERO_APR_REVOLVING"] = "TEASER_ZERO_APR_REVOLVING";
    TeaserPromoType["TEASER_PROMO_BALANCE_TRANSFER_REVOLVING"] = "TEASER_PROMO_BALANCE_TRANSFER_REVOLVING";
    TeaserPromoType["TEASER_PROMO_ZERO_PCT_BT_FEE"] = "TEASER_PROMO_ZERO_PCT_BT_FEE";
    TeaserPromoType["TEASER_PROMO_ZERO_PCT_BT_FEE_AND_2PCT_BT_CASHBACK"] = "TEASER_PROMO_ZERO_PCT_BT_FEE_AND_2PCT_BT_CASHBACK";
})(TeaserPromoType = exports.TeaserPromoType || (exports.TeaserPromoType = {}));
