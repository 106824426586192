"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReverseOrderedTreasuryYieldSeriesIdAndYears = exports.TreasuryYieldSeriesId = exports.StLouisFedRequestType = void 0;
var StLouisFedRequestType;
(function (StLouisFedRequestType) {
    StLouisFedRequestType["fredSeriesObservations"] = "/fred/series/observations";
})(StLouisFedRequestType = exports.StLouisFedRequestType || (exports.StLouisFedRequestType = {}));
// Reference: https://fred.stlouisfed.org/graph/?id=DGS5,DGS10,DGS20,DGS30
/**  @TranslateToPython @TranslateToEnum  */
var TreasuryYieldSeriesId;
(function (TreasuryYieldSeriesId) {
    TreasuryYieldSeriesId["DGS5"] = "DGS5";
    TreasuryYieldSeriesId["DGS10"] = "DGS10";
    TreasuryYieldSeriesId["DGS20"] = "DGS20";
    TreasuryYieldSeriesId["DGS30"] = "DGS30";
})(TreasuryYieldSeriesId = exports.TreasuryYieldSeriesId || (exports.TreasuryYieldSeriesId = {}));
exports.ReverseOrderedTreasuryYieldSeriesIdAndYears = [
    [TreasuryYieldSeriesId.DGS30, 30],
    [TreasuryYieldSeriesId.DGS20, 20],
    [TreasuryYieldSeriesId.DGS10, 10],
    [TreasuryYieldSeriesId.DGS5, 5],
];
