"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitlistSecondaryReason = exports.WaitlistReason = void 0;
var WaitlistReason;
(function (WaitlistReason) {
    WaitlistReason["unsupportedState"] = "unsupportedState";
    WaitlistReason["unsupportedCounty"] = "unsupportedCounty";
    WaitlistReason["waitlistFlagActive"] = "waitlistFlagActive";
    WaitlistReason["unsupportedFirstLienInState"] = "unsupportedLienPositionInState";
})(WaitlistReason = exports.WaitlistReason || (exports.WaitlistReason = {}));
var WaitlistSecondaryReason;
(function (WaitlistSecondaryReason) {
    WaitlistSecondaryReason["volume"] = "volume";
})(WaitlistSecondaryReason = exports.WaitlistSecondaryReason || (exports.WaitlistSecondaryReason = {}));
